import { SAVE_WAITING_COUNT, SAVE_APPROVED_COUNT, SAVE_USERS_PER_PAGE, SAVE_PAGE_NUMBER, SAVE_SEARCH_VALUE, SAVE_LIST_STATE, CLEAN_USER_SETTINGS_STORE } from '../actions/userSettings';

const initialState = {
	waitingCount: 0,
	approvedCount: 0,
	usersPerPage: 25,
	userSearchValue: '',
	pageNumber: 1,
	listState: 0
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SAVE_WAITING_COUNT:
			return Object.assign({}, state, { waitingCount: action.waitingCount });
		case SAVE_APPROVED_COUNT:
			return Object.assign({}, state, { approvedCount: action.approvedCount });
		case SAVE_USERS_PER_PAGE:
			return Object.assign({}, state, { usersPerPage: action.usersPerPage });
		case SAVE_PAGE_NUMBER:
			return Object.assign({}, state, { pageNumber: action.pageNumber });
		case SAVE_SEARCH_VALUE:
			return Object.assign({}, state, { userSearchValue: action.userSearchValue });
		case SAVE_LIST_STATE:
			return Object.assign({}, state, { listState: action.listState });
		case CLEAN_USER_SETTINGS_STORE:
			return Object.assign({}, initialState);
		default: return state;
	}
}
