import {apiURL} from './api';
const url = apiURL + "site-pinchange"

// Update site pin field.
export const updateSitePin = (newId, oldId) => {
  return fetch(url + '/' + oldId, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({pin: newId})
  })
  .then((response) => response.json());
}
