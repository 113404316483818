import { useState } from 'react';
import { DateRange } from "react-date-range";

const DatePicker = (props) => {
  const [ranges, setRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selected'
    }
  ]);

  const handleChanges = ranges => {
    props.changes(ranges.selected)

    setRanges([ranges.selected])
  };

  return (
    <DateRange
      weekStartsOn={1}
      weekdayDisplayFormat={'EEEEEE'}
      showPreview={false}
      showDateDisplay={false}
      editableDateInputs={true}
      onChange={handleChanges}
      moveRangeOnFirstSelection={false}
      ranges={ranges}
      maxDate={new Date()}
    />
  );
}

export default DatePicker;