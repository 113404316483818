import React from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';

import Login from './components/login/Login';
import Users from './components/user/Users';
import User from './components/user/User';
import CurrentUser from './components/user/CurrentUser';
import UserNew from './components/user/UserNew';
import BgColor from './BgColor';
import NotFound from './NotFound';
import Charts from './components/chart/Charts';
import Reports from './components/report/Reports';
import Report from './components/report/Report';
import ReportDownload from './components/report/ReportDownload';
import Sites from './components/site/Sites';
import SiteNew from './components/site/SiteNew';
import Site from './components/site/Site';

import store from './store/Store';
import { saveUser } from './store/actions/user';
import { setJwt } from './store/actions/jwt';
import { saveState } from './localStorage/Storage';
import { createHashHistory } from "history";
import { me } from './ajax/AjaxLogin';

store.subscribe(() =>
  saveState(store.getState())
);

const mapStateToProps = (store) => {
  return {
    user: store.user,
    jwt: store.jwt,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: (user) => dispatch(saveUser(user)),
    setJwt: (jwt) => dispatch(setJwt(jwt))
  }
};

const App = (props) => {
  const history = createHashHistory();

  if (!props.user.id) {
    me().then((data) => {
      if (!data.id) {
        window.location = '/sso/login'; // comment out for dev
      } else {
        const userData = Object.assign({}, data, { password: '' });
        const jwtData = {
          token: 'jwt'
        };
        props.saveUser(userData);
        props.setJwt(jwtData);
      }
    });
  }

  const PrivateNewSiteRoute = ({ children }) => {
    const isAuthenticated = parseInt(store.getState().user.userRole, 10) > 0;
    if (isAuthenticated ) {
      return children
    }

    return <Navigate to="/dashboard/sites" />
  }

  const PrivateUserRoute = ({ children }) => {
    const isAuthenticated = parseInt(store.getState().user.userRole, 10) > 1;
    if (isAuthenticated ) {
      return children
    }

    return <Navigate to="/dashboard/users" />
  }

  return (
    <HashRouter location={history.location} navigator={history}>
      <Routes>
        <Route path="/" element={<BgColor />}>

          <Route path="login" element={<Login />} />

          <Route path="dashboard/reports" element={<Reports />} />
          <Route path="dashboard/reports/download-report" element={<ReportDownload />} />
          <Route path="dashboard/reports/:reportId" element={<Report />} />

          <Route path="dashboard/charts" element={<Charts />} />

          <Route path="dashboard/sites" element={<Sites />} />
          <Route path="dashboard/sites/:sitePin" element={<Site />} />
          <Route
            path="dashboard/sites/new-site"
            element={
              <PrivateNewSiteRoute>
                <SiteNew />
              </PrivateNewSiteRoute>
            }
          />

          <Route path="dashboard/users" element={<Users />} />
          <Route
            path="dashboard/users/:userId"
            element={
              <PrivateUserRoute>
                <User />
              </PrivateUserRoute>
            }
          />
          <Route
            path="dashboard/users/new-user"
            element={
              <PrivateUserRoute>
                <UserNew />
              </PrivateUserRoute>
            }
          />

          <Route path="dashboard/profile" element={<CurrentUser />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </HashRouter>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
