import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavButton from './NavButton';

const mapStateToProps = (store) => {
  return {
    user: store.user
  }
}

class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      width: 0,
      left: 0
    }
  }
  componentDidMount() {
    this.animateLine();
  };

  animateLine = (event) => {
    const el = event ? event.target : document.querySelector('.navLink.active');

    if (el) {
      const width = el.offsetWidth;
      const left = el.offsetLeft;
      this.setState({ width, left });
    } else {
      // defaut view after login
      this.setState({
        width: 152,
        left: 0
      });
    }
  };

  render() {
    return (
      <nav id='mainNav'>
        <ul>
          <NavButton value="Reports" goTo="dashboard/reports" id="reportLink" animateLine={this.animateLine} />
          <NavButton value="Charts" goTo="dashboard/charts" animateLine={this.animateLine} />
          <NavButton value="Sites" goTo="dashboard/sites" animateLine={this.animateLine} />
          {this.props.user.userRole > 1 &&
            <NavButton value="Users" goTo="dashboard/users" id="userLink" animateLine={this.animateLine} />
          }
        </ul>
        <div className="full-width" id="animatedMenu">
          <span id="animatedLine" style={this.state}></span>
        </div>
      </nav>
    );
  }
}

export default connect(mapStateToProps)(Navigation);
