import React, { Component } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser, deleteUser, updateUser } from '../../ajax/AjaxUser';
import { sendPswdRequest } from '../../ajax/AjaxPswdRecover';
import { validateString, validateEmail, validateName, validatePhone } from '../utils/Validator';
import { errorMessages } from './ErrorMessages';
import { getEventPaths } from '../utils/Helpers';
import { findIndex, includes } from 'lodash';
import { setColor } from '../../store/actions/bgcolor';
import store from '../../store/Store';

const mapStateToProps = (store) => {
  return {
    user: store.user,
    usersFullList: store.usersFullList,
    userSettings: store.userSettings,
  }
}

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} location={useLocation()} params={useParams()} {...props} />;
  };

  return Wrapper;
};

class User extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      jobTitle: '',
      company: '',
      email: '',
      phoneNumber: '',
      role: '',
      userRole: 0,
      isApproved: '',
      error: '',
      success: '',
      errorElements: [],
      saving: false,
    }

    store.dispatch(setColor({ color: 'blueDarkBg' }));
  };

  // call for first load
  componentDidMount() {
    document.addEventListener('click', this._checkClicks);
    if (this.props.user.userRole < 2) {
      this.props.navigate('/dashboard/reports');
    }
    this.getUserById(this.props.params.userId);
  };

  // call if user changes
  componentWillReceiveProps(nextProps) {
    this.getUserById(nextProps.params.userId);
  };

  componentWillUnmount() {
    document.removeEventListener('click', this._checkClicks);
  };

  _checkClicks = (evt) => {
    let paths = getEventPaths(evt);
    let checker = true;
    findIndex(paths, function (o) {
      if (o.classList && o.classList.length > 0) {
        if (includes(o.classList.value, 'clickable')) {
          checker = false;
        }
        if (includes(o.classList, 'clickable')) {
          checker = false;
        }
      }
    });

    if (checker) {
      this.setState({
        showRoles: false,
      });
    }
  };

  getUserById = (id) => {
    getUser(id).then((userData) => {
      if (typeof userData.id !== 'undefined') {
        this.setState(userData);
      } else {
        this.props.navigate('/not-found');
      }
    });
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  changeUserRole = (event) => {
    const userRole = parseInt(event.target.name.substr(event.target.name.indexOf('-') + 1), 10);
    const role = ['user', 'sitemanager', 'admin', 'super'][userRole];
    this.setState({ userRole, role });
    this.toggleRoles();
  };

  toggleRoles = () => {
    this.setState({
      showRoles: !this.state.showRoles
    });
  };

  resetMessages = () => {
    this.setState({
      error: '',
      success: '',
      errorElements: []
    });
  };

  validate = () => {
    let isValid = true;
    const { firstName, lastName, email, phoneNumber, jobTitle, company } = this.state;
    let errorElements = this.state.errorElements;

    if (!validateName(firstName)) {
      errorElements.push('firstName');
      isValid = false;
    }

    if (!validateName(lastName)) {
      errorElements.push('lastName');
      isValid = false;
    }

    if (!validateEmail(email)) {
      errorElements.push('email');
      isValid = false;
    }

    if (!validatePhone(phoneNumber)) {
      errorElements.push('phoneNumber');
      isValid = false;
    }

    if (!validateString(jobTitle)) {
      errorElements.push('jobTitle');
      isValid = false;
    }

    if (!validateString(company)) {
      errorElements.push('company');
      isValid = false;
    }

    this.setState({ errorElements });
    return isValid;
  };

  approveUser = () => {
    this.resetMessages();

    // validate and save
    if (this.validate()) {
      this.setState({ saving: true });
      const { firstName, lastName, jobTitle, company, email, phoneNumber,
        role, userRole } = this.state;
      const saveData = {
        firstName, lastName, jobTitle, company, email, phoneNumber,
        role, userRole, isApproved: 1
      };

      updateUser(saveData, this.props.params.userId).then((data) => {
        setTimeout(function () {
          this.setState({ saving: false });
        }.bind(this), 1000);
      });
    }
  };

  saveUser = () => {
    this.resetMessages();

    // validate and save
    if (this.validate()) {
      this.setState({ saving: true });
      const { firstName, lastName, jobTitle, company, email, phoneNumber,
        role, userRole, isApproved } = this.state;
      const saveData = {
        firstName, lastName, jobTitle, company, email, phoneNumber,
        role, userRole, isApproved
      };
      updateUser(saveData, this.props.params.userId).then((data) => {
        if (data.status === 500) {
          this.setState({ error: 'Server error. Please try again later.' });
        } else if (data.status === 409) {
          this.setState({ error: 'A user with this email address already exists.' });
        } else if (data.status === 404) {
          this.setState({ error: 'You are trying to update non-existent user. Please refresh the page.' });
        } else {
          this.setState({ error: '' });
          setTimeout(function () {
            this.setState({ saving: false });
          }.bind(this), 1000);
        }
      });
    }
  };

  deleteUser = () => {
    this.resetMessages();
    deleteUser(this.props.params.userId).then((data) => {
      if (data === 204) {
        this.goNext();
      } else {
        this.setState({ error: 'Server error. Please try again later.' });
      }
    });
  };

  goNext = () => {
    const next = this.state.nextId ? this.state.nextId : '';
    this.props.navigate('/dashboard/users/' + next);
  };

  resetPswd = () => {
    this.resetMessages();
    sendPswdRequest({ email: this.state.email, liveTime: 1440 }).then((data) => {
      if (data.status === 500) {
        this.setState({ error: 'Server error. Please try again later.' });
      } else if (data.status === 404) {
        this.setState({ error: 'You are trying to update non-existent user. Please refresh the page.' });
      } else {
        this.setState({ success: 'Password was successfully changed.' });
      }
    });
  };

  goToList = () => {
    this.props.navigate('/dashboard/users');
  };

  _renderApproved = () => {
    return (
      <div className="buttons">
        <button className="btnDanger" onClick={this.deleteUser}>Delete user</button>
        <button className="btnSuccess" disabled={this.state.saving} onClick={this.saveUser}>Save<span className={`${this.state.saving ? '' : 'hidden'}`}> in progress</span></button>
      </div>
    )
  };

  render() {
    const role = parseInt(this.state.userRole, 10);
    const roles = ['Standard user', 'Site manager', 'Admin user', 'Super user'];
    const roleLabel = roles[role];

    const prev = this.state.prevId ? this.state.prevId : '';
    const next = this.state.nextId ? this.state.nextId : '';

    return (
      <div>
        <div id="userHeader">
          <div className="listNav">
            <Link to={`/dashboard/users/${prev}`} className={`prev ${prev ? 'active' : ''}`}>Previous</Link>
            <Link to={`/dashboard/users/${next}`} className={`next ${next ? 'active' : ''}`}>Next</Link>
          </div>
        </div>
        <div className="userCard">
          <h2 className="floatLeft">{this.state.firstName} {this.state.lastName}</h2>
          <div className="floatRight">
            <button className="closeButton" onClick={this.goToList}></button>
          </div>
          <p className="clear">{parseInt(this.state.isApproved, 10) === 0 ? 'The following user has applied for an admin account.' : 'Edit this user’s details, permissions and/or password:'}</p>
          {this.state.error !== "" && <div className="errorRow mainFormError"><p>{this.state.error}</p></div>}
          {this.state.success !== "" && <div className="successRow"><p>{this.state.success}</p></div>}
          <div className="form" id="userForm">
            <div className="formRow">
              <input
                type="text"
                name="firstName"
                id="firstName"
                style={{ textTransform: 'capitalize' }}
                data-empty={this.state.firstName === '' ? 'true' : 'false'}
                className={`${this.state.errorElements.indexOf('firstName') >= 0 ? 'errorInput' : ''}`}
                value={this.state.firstName}
                onChange={this.handleChange} />
              {this.state.errorElements.indexOf('firstName') >= 0 && <span className="errorInputMsg">{errorMessages['firstName']}</span>}
              <label htmlFor="firstName"><span className="labelTitle">First name</span></label>
            </div>
            <div className="formRow">
              <label htmlFor="userRole" className="selectLabel">
                <span>User level</span>
                <button onClick={this.toggleRoles} className="toggleBtn floatRight clickable">{roleLabel}</button>
              </label>
              {this.state.showRoles &&
                <div className="filterTooltip clickable">
                  <ul>
                    <li>
                      <label htmlFor="type-standard" className={role === 0 ? 'active' : ''}>
                        <span>Standard user</span>
                        <input
                          type="radio"
                          name="type-0"
                          id="type-standard"
                          className={role === 0 ? 'active' : ''}
                          checked={role === 0}
                          onChange={(event) => this.changeUserRole(event)} />
                        <span></span>
                      </label>
                    </li>
                    <li className={`${this.props.user.userRole > 0 ? '' : 'hiddenImportant'}`}>
                      <label htmlFor="type-sitemanager" className={role === 1 ? 'active' : ''}>
                        <span>Site manager</span>
                        <input
                          type="radio"
                          name="type-1"
                          id="type-sitemanager"
                          className={role === 1 ? 'active' : ''}
                          checked={role === 1}
                          onChange={(event) => this.changeUserRole(event)} />
                        <span></span>
                      </label>
                    </li>
                    <li className={`${this.props.user.userRole > 1 ? this.props.user.userRole : 'hiddenImportant'}`}>
                      <label htmlFor="type-admin" className={role === 2 ? 'active' : ''}>
                        <span>Admin user</span>
                        <input
                          type="radio"
                          name="type-2"
                          id="type-admin"
                          className={role === 2 ? 'active' : ''}
                          checked={role === 2}
                          onChange={(event) => this.changeUserRole(event)} />
                        <span></span>
                      </label>
                    </li>
                    <li className={`${this.props.user.userRole > 2 ? '' : 'hiddenImportant'}`}>
                      <label htmlFor="type-super" className={role === 3 ? 'active' : ''}>
                        <span>Super user</span>
                        <input
                          type="radio"
                          name="type-3"
                          id="type-super"
                          className={role === 3 ? 'active' : ''}
                          checked={role === 3}
                          onChange={(event) => this.changeUserRole(event)} />
                        <span></span>
                      </label>
                    </li>
                  </ul>
                </div>
              }
            </div>
            <div className="formRow">
              <input
                type="text"
                name="lastName"
                id="lastName"
                style={{ textTransform: 'capitalize' }}
                data-empty={this.state.lastName === '' ? 'true' : 'false'}
                className={`${this.state.errorElements.indexOf('lastName') >= 0 ? 'errorInput' : ''}`}
                value={this.state.lastName}
                onChange={this.handleChange} />
              {this.state.errorElements.indexOf('lastName') >= 0 && <span className="errorInputMsg">{errorMessages['lastName']}</span>}
              <label htmlFor="lastName"><span className="labelTitle">Last name</span></label>
            </div>
            <div className="formRow">
              <input
                type="text"
                name="jobTitle"
                id="jobTitle"
                style={{ textTransform: 'capitalize' }}
                data-empty={this.state.jobTitle === '' ? 'true' : 'false'}
                className={`${this.state.errorElements.indexOf('jobTitle') >= 0 ? 'errorInput' : ''}`}
                value={this.state.jobTitle}
                onChange={this.handleChange} />
              {this.state.errorElements.indexOf('jobTitle') >= 0 && <span className="errorInputMsg">{errorMessages['jobTitle']}</span>}
              <label htmlFor="jobTitle"><span className="labelTitle">Job title</span></label>
            </div>
            <div className="formRow">
              <input
                type="email"
                name="email"
                id="email"
                data-empty={this.state.email === '' ? 'true' : 'false'}
                className={`${this.state.errorElements.indexOf('email') >= 0 ? 'errorInput' : ''}`}
                value={this.state.email}
                onChange={this.handleChange} />
              {this.state.errorElements.indexOf('email') >= 0 && <span className="errorInputMsg">{errorMessages['email']}</span>}
              <label htmlFor="email">
                <span className="labelTitle">Email</span>
              </label>
            </div>
            <div className="formRow">
              <input
                type="text"
                name="company"
                id="company"
                style={{ textTransform: 'capitalize' }}
                data-empty={this.state.company === '' ? 'true' : 'false'}
                className={`${this.state.errorElements.indexOf('company') >= 0 ? 'errorInput' : ''}`}
                value={this.state.company}
                onChange={this.handleChange} />
              {this.state.errorElements.indexOf('company') >= 0 && <span className="errorInputMsg">{errorMessages['company']}</span>}
              <label htmlFor="company">
                <span className="labelTitle">Company</span>
              </label>
            </div>
            <div className="formRow">
              <input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                data-empty={this.state.company === '' ? 'true' : 'false'}
                className={`${this.state.errorElements.indexOf('phoneNumber') >= 0 ? 'errorInput' : ''}`}
                value={this.state.phoneNumber}
                onChange={this.handleChange} />
              {this.state.errorElements.indexOf('phoneNumber') >= 0 && <span className="errorInputMsg">{errorMessages['phoneNumber']}</span>}
              <label htmlFor="phoneNumber">
                <span className="labelTitle">Phone number</span>
              </label>
            </div>
            {
              this._renderApproved()
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(connect(mapStateToProps, null)(User));
