import {apiURL} from './api';
const url = apiURL + 'user/me';

export const me = () => {
  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin'
  })
  .then((response) => response.json());
}
