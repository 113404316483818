import { SET_JWT, CLEAR_JWT } from '../actions/jwt';

const initialState = {
    token: null
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_JWT:
            return Object.assign({}, state, action.jwt);
        case CLEAR_JWT:
            return Object.assign({}, initialState);
        default: return state;
    }
}
