import {apiURL} from './api';
import { logoutUser } from './AjaxLogout';
const url = apiURL + "chart"

export const chartRequest = (data) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    if (response.status === 404) {
      alert('No data was found for that selection. Please review and try again.');
      return { error: true };
    }
    alert('An error occurred trying to access the download.');
    return { error: true };
  })
  .catch((err) => {
    console.log('Error getting download.');
    console.error(err);
    return { error: true };
  });
}
