import {apiURL} from './api';
import { logoutUser } from './AjaxLogout';
const url = apiURL + "site"

//save site
export const saveSite = (data) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    if (response.status === 409) {
      alert('Site with this pin already exists.');
      return response.json();
    }

    alert('Server error. Please try again later.');
    return response.json();
  });
}

//update site
export const updateSite = (data, id) => {
  return fetch(url + '/' +id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    if (response.status === 409) {
      alert('Site with this pin already exists.');
      return response.json();
    }

    alert('Server error. Please try again later.');
    return response.json();
  });
}

//update some site info
export const updateSiteField = (data, id) => {
  return fetch(url + '/' + id, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    alert('Server error. Please try again later.');
    return response.json();
  });
}

//delete site
export const deleteSite = (id) => {
  return fetch(url + '/' +id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => response.status);
}


//get site
export const getSite = (pin) => {
  return fetch(url + '/' + pin, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => response.json());
}

//get sites
export const getSites = (urlParameters) => {
  let getUrl = url + '?filterBy=' + urlParameters.filterBy + '&filterValue=' + urlParameters.filterValue + '&page=' + urlParameters.page;
  getUrl = typeof urlParameters.pageSize === "undefined" || urlParameters.pageSize < 1 ? getUrl : getUrl + '&pageSize=' + urlParameters.pageSize;
  getUrl = typeof urlParameters.searchValue === "undefined" || urlParameters.searchValue === '' ? getUrl : getUrl + '&search=' + urlParameters.searchValue ;
  getUrl = typeof urlParameters.userRole !== "undefined" ? getUrl + '&userRole=' + urlParameters.userRole : getUrl;
  return fetch(getUrl, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    alert('An error occurred trying to access the site information.');
    return {};
  })
  .catch((err) => {
    console.log('Error getting sites');
    console.error(err);
  });
}
