import { SAVE_REPORTS_PER_PAGE, SAVE_PAGE_NUMBER, SAVE_SEARCH_VALUE, CLEAN_REPORT_SETTINGS_STORE } from '../actions/reportSettings';

const initialState = {
	reportsPerPage: 25,
	searchValue: '',
	pageNumber: 1
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SAVE_REPORTS_PER_PAGE:
			return Object.assign({}, state, { reportsPerPage: action.reportsPerPage });
		case SAVE_PAGE_NUMBER:
			return Object.assign({}, state, { pageNumber: action.pageNumber });
		case SAVE_SEARCH_VALUE:
			return Object.assign({}, state, { searchValue: action.searchValue });
		case CLEAN_REPORT_SETTINGS_STORE:
			return Object.assign({}, initialState);
		default: return state;
	}
}
