import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../ajax/AjaxLogout';
import { getEventPaths } from '../utils/Helpers';
import { findIndex, includes } from 'lodash';

const mapStateToProps = (store) => {
  return {
		user: store.user,
    jwt: store.jwt
  }
};

const Header = (props) => {
  const [state, setStateValues] = useState({
    showInfo: false
  });

  useEffect(() => {
    document.addEventListener('click', _checkClicks);

    // Specify how to clean up after this effect:
    return function cleanup() {
      document.removeEventListener('click', _checkClicks);
    };
  });

  const _checkClicks = (evt) => {
    let paths = getEventPaths(evt);
    let checker = true;
    findIndex(paths, function(o) {
      if (o.classList && o.classList.length > 0) {
        if (includes(o.classList.value, 'clickable')) {
          checker = false;
        }
        if (includes(o.classList, 'clickable')) {
          checker = false;
        }
      }
    });

    if (checker) {
      setStateValues( prevValues => {
        return { ...prevValues, showInfo: false }
      });
    }
  };

  const changeState = () => {
    setStateValues( prevValues => {
      return { ...prevValues, showInfo: !state.showInfo }
    });
  };

  const animateToReports = () => {
    if (props.user.id){
      const el = document.getElementById("reportLink");
      const width = el.offsetWidth;
      const left = el.offsetLeft;
      const animatedLine = document.getElementById("animatedLine");
      animatedLine.style.width = width + "px";
      animatedLine.style.left = left + "px";
    }
  };

  const animateToUsers = () => {
    if (props.user.id) {
      const el = document.getElementById('userLink');
      if (el) {
        const width = el.offsetWidth;
        const left = el.offsetLeft;
        const animatedLine = document.getElementById('animatedLine');
        animatedLine.style.width = width + 'px';
        animatedLine.style.left = left + 'px';
      }
    }
  };

  const profileClickHandler = (event) => {
    changeState(event);
    animateToUsers();
  };

  const logout = (event) => {
    event.preventDefault();
    changeState();
    logoutUser();
  };

  const _renderInfo = () => {
    const userName = props.user.firstName + ' ' + props.user.lastName;
    return (
      <ul id="userInfo" className="clickable">
        <li>
          <button onClick={changeState}>{userName}<span className="downArrow"></span></button>
          <ul className={state.showInfo ? 'visible' : 'hidden'}>
            <li className="successBg"><span>{userName}</span><br/><span>{props.user.company}</span></li>
            <li className="greenDarkBg" style={{paddingBottom:'0'}}><a href="https://stopgo.wates.co.uk/terms-of-usage" rel="noopener noreferrer" target="_blank">Terms of Usage</a></li>
            <li className="greenDarkBg" style={{paddingBottom:'0'}}><a href="https://stopgo.wates.co.uk/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a></li>
            <li className="greenDarkBg" style={{paddingBottom:'0'}}><Link to={`dashboard/profile`} onClick={profileClickHandler}>Update profile</Link></li>
            <li className="greenDarkBg" style={{paddingTop:'32px'}}><Link to='/' onClick={logout}>Log out</Link></li>
          </ul>
        </li>
      </ul>
    )
  };

  return (
    <div id='header'>
      <Link to={`${props.user.id ? '/dashboard/reports' : '/login'}`} id='logo' onClick={animateToReports}/>
      <h1>Stop/Go Observation App Dashboard</h1>
      {props.user.id ? _renderInfo() : null}
    </div>
  );
}

export default connect(mapStateToProps)(Header);
