import {gmAPIKEY} from './api';
import {gmAPIURL} from './api';

//get site
export const getCoordinates = (address) => {
  const addressString = address.split(' ').join('+');
  const url = gmAPIURL + addressString + '&key=' + gmAPIKEY;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => response.json());
}
