import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = (store) => {
  return {
    user: store.user,
  }
}

class Sublist extends Component {
  constructor(props) {
    super();
    this.state = {
      lastTooltipId: ''
    }
  }

  toggleTooltip = (id) => {
    if (this.state.lastTooltipId !== '') this.hideTooltip(this.state.lastTooltipId);
    const tooltip = document.getElementById('tooltip-' + id);
    tooltip.className += ' visible';
    this.setState({ lastTooltipId: id })
  }

  hideTooltip = (id) => {
    const tooltip = document.getElementById('tooltip-' + id);
    if (tooltip) tooltip.classList.remove('visible');
  }

  deleteUser = (id) => {
    this.props.deleteUser(id);
  }

  _renderList = () => {
    return (
      this.props.users.map(user => (
        <tr key={user.id} className="listRow">
          <td className="wideTd"><Link to={`/dashboard/users/${user.id}`}>{user.firstName} {user.lastName}</Link></td>
          <td className="narrowTd transparentFont">
            <Link to={`dashboard/users/${user.id}`}>{user.role}</Link></td>
          <td className="textRight">
            {parseInt(this.props.user.userRole, 10) > 1 && <Link to={`/dashboard/users/${user.id}`} className="floatRight" style={{ position: 'relative' }}>
              <span style={{ textDecoration: 'underline' }}>Edit</span>
            </Link>}
          </td>
          <td style={{ width: '100px' }}>
            {parseInt(this.props.user.userRole, 10) > 1 && <button className="asLink" onClick={() => this.toggleTooltip(user.id)}>Delete</button>}
            <div className="actionTooltip" id={`tooltip-${user.id}`}>
              <p>Are you sure you would like to DELETE this USER</p>
              <button onClick={() => this.deleteUser(user.id)}>Yes</button>
              <button onClick={() => this.hideTooltip(user.id)}>No</button>
            </div>
          </td>
        </tr>
      ))
    )
  }
  render() {
    return (
      <table>
        <tbody>
          {this._renderList()}
        </tbody>
      </table>
    );
  }
}

export default connect(mapStateToProps, null)(Sublist);
