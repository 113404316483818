/* eslint-disable no-unused-vars, no-unused-expressions */
import React, { Component } from 'react';
import paginator from "./PageClass";
import { getEventPaths } from '../utils/Helpers';
import { findIndex, includes } from 'lodash';

import * as Scroll from 'react-scroll';

const changePageState = false;
const scroll = Scroll.animateScroll;

class Paginator extends Component {
  state = {
    showTotals: false,
  }

  componentDidMount () {
    document.addEventListener('click', this._checkClicks);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._checkClicks);
  }

  componentDidUpdate(prevProps, prevState) {
    let that = this;
    if (this.props.currentPage !== prevProps.currentPage) {
      setTimeout(() => {
        that.setState({
          changePageState: false
        });
        // that.changePageState = false;
      }, 250);
    }
  }

  toggleTotals = () => {
    this.setState({showTotals: !this.state.showTotals});
  }

  handlePageSizeChange = (size) => {
    if (size) {
      this.props.changePageSize(size);
      this.toggleTotals();
    }
  }

  handlePageChange = (event, page) => {
    event.persist;
    this._scrollToTop();

    if (page && !this.changePageState) {
      this.props.changePage(page);
      this.setState({
        changePageState: true
      });
    }
  }

  _scrollToTop = () => {
    let duration = this.props.pageSize * 10;
    scroll.scrollToTop({smooth: 'easeInOutCubic', duration: duration > 200 ? duration : 250});
  }

  _checkClicks = (evt) => {
    let paths = getEventPaths(evt);
    let checker = true;

    findIndex(paths, function(o) {
      if (o.classList && o.classList.length > 0) {
        if (includes(o.classList.value, 'clickable')) {
          checker = false;
        }
        if (includes(o.classList, 'clickable')) {
          checker = false;
        }
      }
    });

    if (checker) {
      this.setState({
        showTotals: false
      });
    }
  }

  render() {
    let pageRange = 10;
    const paginationInfo = new paginator(
      this.props.pageSize,
      pageRange
    ).build(this.props.totalItems, this.props.currentPage);

    let pages = [];
    for (let i=paginationInfo.first_page; i<=paginationInfo.last_page; i++){
      pages.push(i);
    }

    const totalItems = parseInt(this.props.totalItems,10);
    const pageSize = parseInt(this.props.pageSize,10);

    return (
      <div className="paginationWrapper">
        {totalItems >= 25 &&
          <div className="columnsLabels floatRight">
            <button onClick={this.toggleTotals} className="toggleBtn clickable">{this.props.pageSize} Results per page</button>
            {this.state.showTotals &&
              <div className="filterTooltip clickable">
                <ul>
                  <li>
                    <div className={`pageSizeLabel ${pageSize === 10 ? "active" : ""}`}>
                      <div className="btn" onClick={()=>this.handlePageSizeChange(10)}>10</div>
                      <span></span>
                    </div>
                  </li>

                  {totalItems >= 25 &&
                    <li>
                      <div className={`pageSizeLabel ${pageSize === 25 ? "active" : ""}`}>
                        <div className="btn" onClick={()=>this.handlePageSizeChange(25)}>25</div>
                        <span></span>
                      </div>
                    </li>
                  }
                  {totalItems >= 50 &&
                    <li>
                      <div className={`pageSizeLabel ${pageSize === 50 ? "active" : ""}`}>
                        <div className="btn" onClick={()=>this.handlePageSizeChange(50)}>50</div>
                        <span></span>
                      </div>
                    </li>
                  }
                  {totalItems >= 100 &&
                    <li>
                      <div className={`pageSizeLabel ${pageSize === 100 ? "active" : ""}`}>
                        <div className="btn" onClick={()=>this.handlePageSizeChange(100)}>100</div>
                        <span></span>
                      </div>
                    </li>
                  }
                  {totalItems >= 250 &&
                    <li>
                      <div className={`pageSizeLabel ${pageSize === 250 ? "active" : ""}`}>
                        <div className="btn" onClick={()=>this.handlePageSizeChange(250)}>250</div>
                        <span></span>
                      </div>
                    </li>
                  }
                </ul>
              </div>
            }
          </div>
        }

        <div id="pagination" className={this.changePageState ? "not-clickable" : ""}>

          {paginationInfo.total_pages > 1 &&
            <div className="pagination-outer-btn">
              <button
                className="pagination-btn svg-btn is-first"
                disabled={!paginationInfo.has_previous_page}
                onClick={(event)=>this.handlePageChange(event, 1)}
              >First</button>
              <button
                className="pagination-btn svg-btn is-prev"
                disabled={!paginationInfo.has_previous_page}
                onClick={(event)=>this.handlePageChange(event, paginationInfo.previous_page)}
              >Prev</button>
            </div>
          }

          {paginationInfo.total_pages > 1 &&
            <ul className="pagination-pages">
              {
                pages.map((item) => {
                  const newKey = item;
                  return <li className="pagination-page" key={newKey}>
                    <button className={`pagination-btn ${parseInt(this.props.currentPage,10) === (newKey) && !this.changePageState ? "active" : ""}`} onClick={(event)=>this.handlePageChange(event, newKey)}>{newKey}</button>
                  </li>
                })
              }
            </ul>
          }

          {paginationInfo.total_pages > 1 &&
            <div className="pagination-outer-btn">
              <button
                className="pagination-btn svg-btn is-next"
                disabled={!paginationInfo.has_next_page}
                onClick={(event)=>this.handlePageChange(event, paginationInfo.next_page)}
              >Next</button>
              <button
                className="pagination-btn svg-btn is-last"
                disabled={!paginationInfo.has_next_page}
                onClick={(event)=>this.handlePageChange(event, paginationInfo.total_pages)}
              >Last</button>
            </div>
          }

        </div>

      </div>
    )
  }
}

export default Paginator;