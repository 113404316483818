/* eslint-disable default-case */
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DatePicker from '../utils/DateRangePicker';
import { getSites } from '../../ajax/AjaxDownload';
import { chartRequest } from '../../ajax/AjaxChart';
import { getRegions } from '../site/Regions';
import { getBusiness } from '../site/Business';
import { setColor } from '../../store/actions/bgcolor';
import store from '../../store/Store';
import { getLables } from '../../ajax/AjaxReport';
import LoadingScreenLoggedIn from '../utils/LoadingScreenLoggedIn';
import { covertObjectToPinsArray, isMatchTitle, getVerticalCoords } from '../utils/Helpers';
import { DebounceInput } from 'react-debounce-input/lib/Component';
import { findIndex, includes } from 'lodash';
import { getEventPaths } from '../utils/Helpers';
import { Pie, HorizontalBar, Line } from 'react-chartjs-2';
import 'chartjs-plugin-labels';

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} {...props} />;
  };

  return Wrapper;
};

class Charts extends Component {
  constructor() {
    super();
    const todayString = moment(new Date()).format('Do MMM');
    const endDate = new Date().setMonth(new Date().getMonth() - 1);
    const labelValue = moment(endDate).format('Do MMM') + ' - ' + todayString
    this.state = {
      btnActive: false,
      showDropdown: false,
      showDatepicker: false,
      isLoading: true,
      showResetSearch: false,
      searchValue: '',
      labelText: 'The last month',
      labelValue: labelValue,
      positiveCheck: true,
      negativeCheck: true,
      isPositive: '',
      dateRange: '&dateRange=30',
      regions: getRegions(),
      business: getBusiness(),
      originalRegions: getRegions(),
      originalBusiness: getBusiness(),
      labelList: [],
      searchRegion: '',
      showRegions: false,
      showBusiness: false,
      showTypes: false,
      showLabels: false,
      regionsToSend: [],
      businessToSend: [],
      sitesToSend: [],
      labelsToSend: [],
      allowDownload: false,
      isChecked: 1,
      elementFloatAbove: false,
      resetFilterState: false,
      // charts
      loadingCharts: false,
      showCharts: false,
      negativeChart: false,
      positiveChart: false,
      stackedChart: false,
      hazardChart: false,
      pieChart: false,
      stopGoChart: false,
    }

    store.dispatch(setColor({ color: 'purpleLightBg' }));
  };

  // go back to reports page
  goToList = () => {
    this.props.negative('/dashboard/reports/');
  };

  // call for first load
  componentDidMount() {
    this.getSitesList();
    getLables().then(reportLabelsData => {
      const labels = reportLabelsData._embedded.label;

      this.setState({
        labelList: Object.assign(labels, {})
      });
    });

    document.addEventListener('click', this._checkClicks);
    window.addEventListener('scroll', this.handleFloatScroll);
  };

  componentWillUnmount() {
    document.removeEventListener('click', this._checkClicks);
    window.removeEventListener('scroll', this.handleFloatScroll);
  };

  handleFloatScroll = (event) => {
    if (this.refs.floatArea) {
      if (getVerticalCoords(this.refs.floatArea) > 0) {
        this.setState({
          elementFloatAbove: true
        });
      } else {
        this.setState({
          elementFloatAbove: false
        });
      }
    }
  };

  _checkClicks = (evt) => {
    let paths = getEventPaths(evt);
    let checker = true;
    findIndex(paths, function (o) {
      if (o.classList && o.classList.length > 0) {
        if (includes(o.classList.value, 'clickable')) {
          checker = false;
        }
        if (includes(o.classList, 'clickable')) {
          checker = false;
        }
      }
    });

    if (checker) {
      this.setState({
        showDropdown: false,
        showDatepicker: false,
        showRegions: false,
        showBusiness: false,
        showTypes: false,
        showLabels: false
      });
    }
  };

  getSitesList = (searchValue, regionsToSend, businessToSend, labelsToSend) => {
    getSites({ searchValue, regionsToSend, businessToSend, labelsToSend }).then((sitesData) => {
      const sites = sitesData._embedded.download_report;
      this.setState({
        sites,
        isLoading: false,
        sitesToSend: []
      });
      this.selectNone();
    })
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    }, function () {
      this.filterItem(name);
    });
  };

  showDropdown = () => {
    this.state.showDatepicker ? this.setState({ showDatepicker: !this.state.showDatepicker }) : this.setState({ showDropdown: !this.state.showDropdown });

    this.setState({
      showSites: false,
      showRegions: false,
      showBusiness: false,
      showTypes: false,
      showResetSearch: false
    });
  };

  handleSelect = (date) => {
    this.setState({
      startDate: moment(date.startDate).format('YYYY-MM-DD'),
      endDate: moment(date.endDate).format('YYYY-MM-DD'),
      btnActive: true
    });
  };

  showDatepicker = () => {
    this.setState({
      showRegions: false,
      showBusiness: false,
      showTypes: false,
      showDatepicker: !this.state.showDatepicker,
      showDropdown: !this.state.showDropdown,
      btnActive: false,
      showResetSearch: false,
      showLabels: false
    });
  };

  changeRange = () => {
    const labelText = 'Custom';
    const labelValue = moment(new Date(this.state.startDate)).format('Do MMM, YY') + ' - ' + moment(new Date(this.state.endDate)).format('Do MMM, YY');

    this.setState({
      showDatepicker: false,
      resetFilterState: true,
      labelText,
      labelValue
    });

    this.updateDate(
      2,
      this.state.startDate,
      this.state.endDate
    );
  };

  changeDate = (type, value, labelType) => {
    this.updateDate(type, value);
    const today = new Date();
    const todayString = moment(today).format('Do MMM');
    let labelText = '';
    let labelValue = '';
    let endDate = '';
    let isChecked = 0;

    switch (labelType) {
      case 0:
        endDate = new Date().setMonth(new Date().getMonth() - 2);
        labelText = 'The last 2 months';
        labelValue = moment(endDate).format('Do MMM') + ' - ' + todayString;
        break;
      case 1:
        endDate = new Date().setMonth(new Date().getMonth() - 1);
        labelText = 'The last month';
        labelValue = moment(endDate).format('Do MMM') + ' - ' + todayString;
        isChecked = 1;
        break;
      case 2:
        endDate = new Date().setDate(new Date().getDate() - 7);
        labelValue = moment(endDate).format('Do MMM') + ' - ' + todayString;
        labelText = 'The last week';
        isChecked = 2;
        break;
      case 3:
        labelText = 'Today';
        labelValue = todayString;
        isChecked = 3;
        break;
    }

    this.setState({
      showDropdown: false,
      labelText,
      labelValue,
      isChecked,
      resetFilterState: true
    });
  };

  // set date range
  updateDate = (type, value, valueTo) => {
    switch (type) {
      case (0):
        this.setState({
          dateRange: '&dateRange=' + value
        });
        break;
      case (1):
        this.setState({
          dateRange: '&dateFrom=' + value
        });
        break;
      case (2):
        this.setState({
          dateRange: '&dateFrom=' + value + '&dateTo=' + valueTo
        });
        break;
    }
  };

  resetSearch = () => {
    this.setState({
      searchValue: '',
      showResetSearch: false
    });

    this.searchSites();
  };

  search = (event) => {
    const { value } = event.target;
    this.setState({
      searchValue: value,
      showResetSearch: true,
      resetFilterState: true
    });
    if (value.length > 2) {
      this.searchSites(value);
    } else {
      this.searchSites();
    }
  };

  // seacrh reports on the fly
  searchSites = (searchValue) => {
    this.getSitesList(
      searchValue,
      this.state.regionsToSend,
      this.state.businessToSend
    );
  };

  handleReportType = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    this.setState({
      [name]: value
    }, function () {
      this.updateReportType();
    });
  };

  updateReportType = () => {
    const { negativeCheck, positiveCheck } = this.state;
    let isPositive = '';
    if (negativeCheck && !positiveCheck) {
      isPositive = '0';
      this.setState({
        isPositive: isPositive,
        resetFilterState: true
      });
    }

    if (!negativeCheck && positiveCheck) {
      isPositive = '1';
      this.setState({
        isPositive: isPositive,
        resetFilterState: true
      });
    }

    if ((!negativeCheck && !positiveCheck) || (negativeCheck && positiveCheck)) {
      isPositive = '';
      this.setState({
        isPositive: '',
        resetFilterState: true
      });
    }
  };

  toggleRegions = () => {
    this.setState({
      showRegions: !this.state.showRegions,
      showBusiness: false,
      showTypes: false,
      showDatepicker: false,
      showDropdown: false,
      showResetSearch: false,
      showLabels: false
    });
  };

  toggleBusiness = () => {
    this.setState({
      showRegions: false,
      showBusiness: !this.state.showBusiness,
      showTypes: false,
      showDatepicker: false,
      showDropdown: false,
      showResetSearch: false,
      showLabels: false
    });
  };

  toggleLabels = () => {
    this.setState({
      showRegions: false,
      showBusiness: false,
      showTypes: false,
      showDatepicker: false,
      showDropdown: false,
      showResetSearch: false,
      showLabels: !this.state.showLabels
    });
  };

  toggleTypes = () => {
    this.setState({
      showRegions: false,
      showBusiness: false,
      showTypes: !this.state.showTypes,
      showDatepicker: false,
      showDropdown: false,
      showResetSearch: false,
      showLabels: false
    });
  };

  filterItem(type) {
    let searchValue = '';
    let originalValues = [];

    if (type === 'searchRegion') {
      searchValue = this.state.searchRegion;
      originalValues = this.state.originalRegions;
    } else {
      searchValue = this.state.searchBusiness;
      originalValues = this.state.originalBusiness;
    }

    if (searchValue.length > 0) {
      const matchItems = originalValues.filter(item => isMatchTitle(item.name, searchValue));
      type === 'searchRegion' ? this.setState({ regions: matchItems }) : this.setState({ business: matchItems });
    } else if (searchValue.length === 0) {
      type === 'searchRegion' ? this.setState({ regions: originalValues }) : this.setState({ business: originalValues });
    }
  };

  changeParentAndSave = (event, type) => {
    const { target } = event;
    const name = target.name;
    let regionsToSend = this.state.regionsToSend;
    let businessToSend = this.state.businessToSend;

    if (target.checked) {
      target.parentNode.classList.add('active');
      if (type === 0) {
        regionsToSend.push(name);
        this.setState({ regionsToSend, resetFilterState: true });
        this.getSitesList(
          this.state.searchValue,
          regionsToSend,
          this.state.businessToSend
        );
      } else {
        businessToSend.push(name);
        this.setState({ businessToSend, resetFilterState: true });
        this.getSitesList(
          this.state.searchValue,
          this.state.regionsToSend,
          businessToSend
        );
      }
    } else {
      target.parentNode.classList.remove('active');
      if (type === 0) {
        var indexA = regionsToSend.indexOf(name);
        regionsToSend.splice(indexA, 1);
        this.setState({ regionsToSend, resetFilterState: true });
        this.getSitesList(
          this.state.searchValue,
          regionsToSend,
          this.state.businessToSend
        );
      } else {
        var indexB = businessToSend.indexOf(name);
        businessToSend.splice(indexB, 1);
        this.setState({
          businessToSend,
          resetFilterState: true
        });
        this.getSitesList(
          this.state.searchValue,
          this.state.regionsToSend,
          businessToSend
        );
      }
    }
  };

  changeLabelAndSave = (event, id) => {
    const { target } = event;
    let labelsToSend = this.state.labelsToSend;

    if (target.checked) {
      labelsToSend.push(id)
    } else {
      const index = labelsToSend.indexOf(id);
      labelsToSend.splice(index, 1);
    }

    this.setState({ labelsToSend, resetFilterState: true });
  };

  handleSiteCheckbox = (event, targetSpecific) => {
    const target = targetSpecific ? targetSpecific : event.target;
    const name = target.name;
    let sitesToSend = this.state.sitesToSend;
    if (target.checked) {
      sitesToSend.push(name);
      this.setState({
        sitesToSend,
        allowDownload: true
      });
    } else {
      var index = sitesToSend.indexOf(name);
      sitesToSend.splice(index, 1);
      this.setState({ sitesToSend });

      if (sitesToSend.length === 0) {
        this.setState({ allowDownload: false });
      }
    }
  };

  selectAll = (event) => {
    const buttons = document.getElementsByClassName('downloadChecks');

    for (var i = 0, j = buttons.length; i < j; i++) {
      buttons[i].classList.remove('active');
    }

    event.target.classList.add('active');
    const checkboxes = document.getElementsByClassName('siteCheckbox');
    const sitesToSend = [];
    for (var k = 0, l = checkboxes.length; k < l; k++) {
      checkboxes[k].checked = true;
    }

    this.state.sites.map(site => (
      sitesToSend.push(site.pin)
    ));

    this.setState({
      sitesToSend,
      allowDownload: true
    });
  };

  selectNone = (event) => {
    const buttons = document.getElementsByClassName('downloadChecks');

    for (var i = 0, j = buttons.length; i < j; i++) {
      buttons[i].classList.remove('active');
    }

    if (typeof event !== 'undefined') event.target.classList.add('active');
    const checkboxes = document.getElementsByClassName('siteCheckbox');

    for (var k = 0, l = checkboxes.length; k < l; k++) {
      checkboxes[k].checked = false;
    }

    this.setState({ sitesToSend: [], allowDownload: false });
  };

  goBack = () => {
    this.setState({
      showCharts: false,
      negativeChart: false,
      positiveChart: false,
      stackedChart: false,
      hazardChart: false,
      pieChart: false,
      stopGoChart: false,
    });

    this.resetFilters();
  };

  resetFilters = () => {
    const todayString = moment(new Date()).format('Do MMM');
    const endDate = new Date().setMonth(new Date().getMonth() - 1);
    const labelValue = moment(endDate).format('Do MMM') + ' - ' + todayString;

    this.setState({
      searchValue: '',
      labelText: 'The last month',
      labelValue: labelValue,
      positiveCheck: true,
      negativeCheck: true,
      isPositive: '',
      dateRange: '&dateRange=30',
      searchRegion: '',
      showRegions: false,
      showBusiness: false,
      showTypes: false,
      showLabels: false,
      regionsToSend: [],
      businessToSend: [],
      sitesToSend: [],
      labelsToSend: [],
      resetFilterState: false,
      allowDownload: false,
      isLoading: false,
      loadingCharts: false
    });

    this.getSitesList();
  };

  /* get charts */
  getCharts = () => {
    this.setState({
      loadingCharts: true,
    });

    const _self = this;
    const sites = covertObjectToPinsArray(this.state.sites);
    const sitesToSendLength = this.state.sitesToSend.length;
    const regionsToSendLength = this.state.regionsToSend.length;
    const businessToSendLength = this.state.businessToSend.length;
    const sitesType = sites.length === sitesToSendLength ? 3 : (sitesToSendLength > 1 ? 2 : sitesToSendLength);
    const regionsType = this.state.originalRegions.length === regionsToSendLength ? 3 : (regionsToSendLength > 1 ? 2 : regionsToSendLength);
    const businessType = this.state.originalBusiness.length === businessToSendLength ? 3 : (businessToSendLength > 1 ? 2 : businessToSendLength);

    // Graphs
    chartRequest({
      sites: this.state.sitesToSend,
      regions: this.state.regionsToSend,
      business: this.state.businessToSend,
      labels: this.state.labelsToSend,
      dateRange: this.state.dateRange,
      dateLabel: this.state.labelValue,
      isPositive: this.state.isPositive,
      sitesType,
      regionsType,
      businessType
    }).then((data) => {
      if (data && !data.error) {
        _self.setState({
          showCharts: true,
          negativeChart: data.graphs.topNegative || false,
          positiveChart: data.graphs.topPositive || false,
          stackedChart: {
            positive: data.graphs.positiveCount || false,
            negative: data.graphs.negativeCount || false,
          },
          hazardChart: data.graphs.hazards || false,
          stopGoChart: data.graphs.totalStopGo || false,
          loadingCharts: false,
        });
      } else {
        _self.resetFilters();
      }
    });
  };

  _renderList = () => {
    return (
      this.state.sites.map(site => (
        <tr key={site.pin} className="listRow">
          <td>{site.name}</td>
          <td className="textRight">
            <label htmlFor={site.pin}>
              <input type="checkbox" className="siteCheckbox" name={site.pin} id={site.pin} onChange={this.handleSiteCheckbox} />
              <span></span>
            </label>
          </td>
        </tr>
      ))
    )
  };

  _renderLoading = () => {
    return (<LoadingScreenLoggedIn />);
  };

  _renderRegions = () => {
    return (
      <ul>
        {this.state.regions.map((region) => {
          const regionId = region.name.replace(/ /g, '+');
          const isChecked = this.state.regionsToSend.indexOf(regionId) >= 0;
          return (
            <li key={region.abbr}>
              <label htmlFor={regionId} className={isChecked ? 'active' : ''}>
                <span>{region.name}</span>
                <input type="checkbox" name={regionId} id={regionId} checked={isChecked} onChange={(event) => this.changeParentAndSave(event, 0)} />
                <span></span>
              </label>
            </li>
          )
        })}
      </ul>
    )
  };

  _renderBusiness = () => {
    return (
      <ul>
        {this.state.business.map((business) => {
          const businessId = business.name.replace(/ /g, '+');
          const isChecked = this.state.businessToSend.indexOf(businessId) >= 0;
          return (
            <li key={business.abbr}>
              <label htmlFor={businessId} className={isChecked ? 'active' : ''}>
                <span>{business.name}</span>
                <input type="checkbox" name={businessId} id={businessId} checked={isChecked} onChange={(event) => this.changeParentAndSave(event, 1)} />
                <span></span>
              </label>
            </li>
          )
        })}
      </ul>
    )
  };

  _renderLabels = () => {
    return (
      <ul>
        {this.state.labelList.map((label) => {
          const isChecked = this.state.labelsToSend.indexOf(label.id) >= 0;
          return (
            <li key={label.id}>
              <label htmlFor={label.id} className={isChecked ? 'active' : ''}>
                <span className="label">{label.label}</span>
                <input
                  type="checkbox"
                  name={label.name}
                  id={label.id}
                  checked={isChecked}
                  onChange={(event) => this.changeLabelAndSave(event, label.id)} />
                <span></span>
              </label>
            </li>
          )
        })}
      </ul>
    )
  };

  // Chart Renders
  _renderStackedChart = (positive, negative) => {
    const barOptions = {
      maintainAspectRatio: true,
      scales: {
        yAxes: [{
          ticks: {
            display: false
          }
        }]
      }
    };

    const barData = {
      labels: ['Stop / Go Card Submissions'],
      datasets: [
        {
          label: 'Positive',
          stack: 'stack1',
          backgroundColor: '#7ac143',
          borderColor: '#7ac143',
          borderWidth: 1,
          hoverBackgroundColor: '#7ac143',
          hoverBorderColor: '#7ac143',
          data: [positive],
        },
        {
          label: 'Negative',
          stack: 'stack1',
          backgroundColor: '#e9382b',
          borderColor: '#e9382b',
          borderWidth: 1,
          hoverBackgroundColor: '#e9382b',
          hoverBorderColor: '#e9382b',
          data: [negative],
        }
      ],
    };

    return (
      <HorizontalBar data={barData} height={120} options={barOptions} />
    )
  };

  _renderHorizontalBarChart = (data, color) => {
    const keys = [];
    const values = [];

    for (const [key, value] of Object.entries(data)) {
      keys.push(key);
      values.push(value);
    };

    const barData = {
      labels: keys,
      datasets: [
        {
          backgroundColor: 'rgba(' + color + ',0.2)',
          borderColor: 'rgba(' + color + ',1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(' + color + ',0.4)',
          hoverBorderColor: 'rgba(' + color + ',1)',
          data: values
        }
      ]
    };

    return (
      <HorizontalBar data={barData} options={{ legend: { display: false } }} />
    )
  };

  _renderPieCart = (data) => {
    const colors = ['#962658', '#01D730', '#255FD9', '#D97786', '#64AEC8', '#0DF69C', '#EA9434', '#0A7F45', '#AE78DC', '#1F05F9', '#F41090', '#3E3C67', '#C5C33A'];
    const pieOptions = {
      responsive: true,
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      animation: false,
      plugins: {
        labels: {
          render: function (args) {
            return args.label + ' ' + args.percentage + '%';
          },
          position: 'outside',
          fontColor: ['black'],
          precision: 1,
          outsidePadding: 4
        }
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            try {
              let label = ' ' + data.labels[tooltipItem.index] || '';

              if (label) {
                label += ': ';
              }

              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
                return parseFloat(accumulator) + parseFloat(curValue);
              });

              label += Number((value / sum) * 100).toFixed(1) + '%';
              return label;
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    };

    const labels = [];
    const values = [];

    for (const [key, value] of Object.entries(data)) {
      labels.push(key);
      values.push(value);
    };

    const pieData = {
      labels: labels,
      datasets: [{
        data: values,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      }]
    };

    return (
      <Pie data={pieData} options={pieOptions} />
    )
  };

  _renderJoinChart = (positive, negative) => {
    const pickHighest = (obj, num = 3) => {
      const requiredObj = {};
      if (num > Object.keys(obj).length) {
        return false;
      };

      Object.keys(obj).sort((a, b) => obj[b] - obj[a]).forEach((key, ind) => {
        if (ind < num) {
          requiredObj[key] = obj[key];
        }
      });

      return requiredObj;
    };

    const topPos = pickHighest(positive);
    const topNeg = pickHighest(negative);
    const data = [];

    for (const [key, value] of Object.entries(topPos)) {
      data.push({
        label: key,
        value: value,
        color: '113,179,124',
      });
    };

    for (const [key, value] of Object.entries(topNeg)) {
      data.push({
        label: key,
        value: value,
        color: '255,99,132',
      });
    };

    const sortedData = data.sort(function (a, b) {
      return b.value - a.value;
    });

    const labels = [];
    const dataset = {
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
      hoverBackgroundColor: [],
      hoverBorderColor: [],
    };

    sortedData.forEach(item => {
      labels.push(item.label);
      dataset.data.push(item.value);
      dataset.backgroundColor.push('rgba(' + item.color + ',0.2)');
      dataset.borderColor.push('rgba(' + item.color + ',1)');
      dataset.hoverBackgroundColor.push('rgba(' + item.color + ',0.4)');
      dataset.hoverBorderColor.push('rgba(' + item.color + ',1)');
    });

    return (
      <HorizontalBar data={{ labels: labels, datasets: [dataset] }} options={{ legend: { display: false } }} />
    )
  };

  _renderStopGoChart = (data) => {
    const stopValues = Object.values(data.stop);
    const goValues = Object.values(data.go);
    const labels = [];

    for (const key of Object.keys(data.go)) {
      labels.push(moment(key).format('MMMM YYYY'));
    }

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: false,
        },
      },
    };

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Stop',
          data: stopValues,
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
        },
        {
          label: 'Go',
          data: goValues,
          backgroundColor: 'rgba(113,179,124,0.2)',
          borderColor: 'rgba(113,179,124,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(113,179,124,0.4)',
          hoverBorderColor: 'rgba(113,179,124,1)',
        },
      ],
    };

    return (
      <Line options={options} data={chartData} />
    )
  };

  // Content Render
  _renderContent = () => {
    // Charts
    const showCharts = this.state.showCharts;
    const negativeChart = this.state.negativeChart;
    const positiveChart = this.state.positiveChart;
    const stackedChart = this.state.stackedChart;
    const hazardChart = this.state.hazardChart;
    const stopGoChart = this.state.stopGoChart;

    const isChecked = this.state.isChecked;
    let today = new Date();
    today = moment(today).format('YYYY-MM-DD');
    const regionsLabel = this.state.regionsToSend.length > 0 ? 'Selected regions' : 'All regions';
    const businessLabel = this.state.businessToSend.length > 0 ? ' Selected business' : 'All business';
    const labelLabel = this.state.labelsToSend.length > 0 ? 'Selected Labels' : 'No Labels';
    const typeLabel =
      this.state.positiveCheck && this.state.negativeCheck
        ? 'All types'
        : this.state.positiveCheck && !this.state.negativeCheck
          ? 'Positive'
          : !this.state.positiveCheck && this.state.negativeCheck
            ? 'Negative' : 'None Selected';

    return (
      <div>
        {this.state.loadingCharts ? this._renderLoading()
          : <div>
            <div
              id="floatBanner"
              ref="floatArea"
              className={this.state.elementFloatAbove ? 'floatAbove is-chart-banner' : 'is-chart-banner'}
            >
              <div className="float-inner-wrapper">
                <div id="floatBannerInner" className="is-charts">
                  {
                    showCharts ? '' :
                      <div className="floatLeft">
                        <span className="transparentFont">Select</span>
                        <button
                          className="downloadChecks"
                          onClick={this.selectAll}
                        >All</button>
                        <button
                          className="downloadChecks"
                          onClick={this.selectNone}
                        >None</button>
                      </div>
                  }
                  <div className="floatRight">
                    {
                      showCharts ? <button className={`successButton ${this.state.allowDownload ? 'active' : ''}`} onClick={this.goBack} disabled={!this.state.showCharts}>&lt; Back</button> :
                        <button className={`successButton ${this.state.allowDownload ? 'active' : ''}`} onClick={this.getCharts} disabled={!this.state.allowDownload}>Show charts</button>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="reportDetail reportDownload">
              {
                showCharts ?
                  <div className="floatLeft clear chartDetails">
                    {
                      stackedChart ?
                        <div className="chartContainer">
                          <h2 className="chartTitle">Stop / Go Card Submissions</h2>
                          {this._renderStackedChart(stackedChart.positive, stackedChart.negative)}
                        </div>
                        : ''
                    }
                    {
                      positiveChart ?
                        <div className="chartContainer">
                          <h2 className="chartTitle">Top 5 Positive Observations</h2>
                          {this._renderHorizontalBarChart(positiveChart, '113,179,124')}
                        </div>
                        : ''
                    }
                    {
                      negativeChart ?
                        <div className="chartContainer">
                          <h2 className="chartTitle">Top 5 Hazards</h2>
                          {this._renderHorizontalBarChart(negativeChart, '255,99,132')}
                        </div>
                        : ''
                    }
                    {
                      hazardChart ?
                        <div className="chartContainer">
                          <h2 className="chartTitle">Hazard Causes</h2>
                          {this._renderPieCart(hazardChart)}
                        </div>
                        : ''
                    }
                    {
                      positiveChart && negativeChart ?
                        <div className="chartContainer">
                          <h2 className="chartTitle">Combined Top 6</h2>
                          {this._renderJoinChart(positiveChart, negativeChart)}
                        </div>
                        : ''
                    }
                    {
                      stopGoChart ?
                        <div className="chartContainer">
                          <h2 className="chartTitle">Total Stop/Go</h2>
                          {this._renderStopGoChart(stopGoChart)}
                        </div>
                        : ''
                    }
                  </div>
                  : <div>
                    <h2 className="floatLeft">Create chart</h2>
                    <div className="filterRow fullWidth clear">
                      <div className="filterContainer">
                        <div className="columnsLabels">
                          <ul id="dateRangeList">
                            <li>
                              <button
                                onClick={this.showDropdown}
                                className="toggleBtn blueBtn dateBtn clickable"
                              >
                                <span className="labelAbove">Select date range</span>
                                <span className="label">{this.state.labelText}</span>
                                <span className="label">&nbsp;{this.state.labelValue}</span>
                              </button>
                              <ul className={this.state.showDropdown ? 'visible clickable' : 'clickable'}>
                                <li>
                                  <button
                                    onClick={() => this.changeDate(0, 60, 0)}
                                    className={`${isChecked === 0 ? 'asCheckbox' : ''}`}
                                  >The last 2 months</button>
                                </li>
                                <li>
                                  <button
                                    onClick={() => this.changeDate(0, 30, 1)}
                                    className={`${isChecked === 1 ? 'asCheckbox' : ''}`}
                                  >The last month</button>
                                </li>
                                <li>
                                  <button
                                    onClick={() => this.changeDate(0, 7, 2)}
                                    className={`${isChecked === 2 ? 'asCheckbox' : ''}`}
                                  >The last week</button>
                                </li>
                                <li>
                                  <button
                                    onClick={() => this.changeDate(1, today, 3)}
                                    className={`${isChecked === 3 ? 'asCheckbox' : ''}`}
                                  >Today</button>
                                </li>
                                <li>
                                  <button
                                    className="datePickerButton"
                                    onClick={this.showDatepicker}
                                  >Select a date range</button>
                                </li>
                              </ul>
                              <div className={`datepicker ${this.state.showDatepicker ? 'visible clickable' : 'clickable'}`}>
                                {
                                  this.state.showDatepicker &&
                                  <div>
                                    <DatePicker changes={this.handleSelect} />
                                    <div className="doneButton">
                                      <button
                                        disabled={!this.state.btnActive}
                                        className={` ${this.state.btnActive ? 'active' : ''}`}
                                        onClick={this.changeRange}
                                      >Done</button>
                                    </div>
                                  </div>
                                }
                              </div>
                            </li>
                          </ul>

                          <ul>
                            <li style={{ position: 'relative' }}>
                              <button
                                onClick={this.toggleRegions}
                                className="toggleBtn blueBtn clickable"
                              >{regionsLabel}</button>
                              {this.state.showRegions &&
                                <div className="limitedDropdown filterTooltip clickable">
                                  <input
                                    type="text"
                                    name="searchRegion"
                                    placeholder="Filter by region"
                                    value={this.state.searchRegion}
                                    onChange={this.handleInputChange} />
                                  {this._renderRegions()}
                                </div>
                              }
                            </li>
                          </ul>

                          <ul>
                            <li style={{ position: 'relative' }}>
                              <button
                                onClick={this.toggleBusiness}
                                className="toggleBtn blueBtn clickable"
                              >{businessLabel}</button>
                              {this.state.showBusiness &&
                                <div className="limitedDropdown filterTooltip clickable">
                                  <input
                                    type="text"
                                    name="searchBusiness"
                                    placeholder="Filter by business"
                                    value={this.state.searchBusiness}
                                    onChange={this.handleInputChange} />
                                  {this._renderBusiness()}
                                </div>
                              }
                            </li>
                          </ul>

                          <ul>
                            <li style={{ position: 'relative' }}>
                              <button
                                onClick={this.toggleLabels}
                                className="toggleBtn blueBtn clickable"
                              >{labelLabel}</button>
                              {this.state.showLabels && (
                                <div className="limitedDropdown filterTooltip clickable">
                                  {this._renderLabels()}
                                </div>
                              )}
                            </li>
                          </ul>

                          <ul>
                            <li style={{ position: 'relative' }}>
                              <button
                                onClick={this.toggleTypes}
                                className="toggleBtn blueBtn clickable"
                              >{typeLabel}</button>
                              {this.state.showTypes && (
                                <div className="filterTooltip clickable">
                                  <ul>
                                    <li>
                                      <label
                                        htmlFor="positiveCheck"
                                        className={this.state.positiveCheck ? 'active' : ''}>
                                        <span className="label">Positive</span>
                                        <input
                                          type="checkbox"
                                          name="positiveCheck"
                                          id="positiveCheck"
                                          checked={this.state.positiveCheck}
                                          onChange={this.handleReportType} /><span></span>
                                      </label>
                                    </li>
                                    <li>
                                      <label
                                        htmlFor="negativeCheck"
                                        className={this.state.negativeCheck ? 'active' : ''}>
                                        <span className="label">Negative</span>
                                        <input
                                          type="checkbox"
                                          name="negativeCheck"
                                          id="negativeCheck"
                                          checked={this.state.negativeCheck}
                                          onChange={this.handleReportType} /><span></span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="fullWidth clear downloadHeader">
                      <div className="searchRow clear">
                        <div className="searchBar clear">
                          <DebounceInput
                            type="text"
                            name="search"
                            id="search"
                            className="searchInput"
                            placeholder="Search"
                            value={this.state.searchValue}
                            onChange={this.search}
                            minLength={2}
                            debounceTimeout={500} />
                          <button
                            className={`cancelSearch ${this.state.showResetSearch ? 'visible' : ''}`}
                            onClick={this.resetSearch}
                          ></button>
                        </div>

                        <div className="floatRight">
                          <button
                            className="actionButton green"
                            onClick={this.resetFilters}
                            disabled={!this.state.resetFilterState}
                          >Reset Filters</button>
                        </div>
                      </div>
                    </div>

                    <div className="list" id="downloadList">
                      <table>
                        <tbody>
                          {this._renderList()}
                        </tbody>
                      </table>
                    </div>
                  </div>
              }
            </div>
          </div>
        }
      </div>
    )
  };

  render() {
    return (
      <div>
        {this.state.isLoading ? this._renderLoading() : this._renderContent()}
      </div>
    )
  };
}

export default withParams(Charts);
