export const convertArrayToMap = function (data) {
  const categoryMap = [];
  data.forEach(function (item) {
    if (!categoryMap[item.date]) {
      categoryMap[item.date] = [];
    }
    categoryMap[item.date].push(item);
  });
  return categoryMap;
};

export const covertObjectToIdsArray = function (data) {
  const idsArray = [];
  data.forEach(function (item) {
    idsArray.push(parseInt(item.id, 10));
  });
  return idsArray;
};

export const covertObjectToPinsArray = function (data) {
  const pinsArray = [];
  data.forEach(function (item) {
    pinsArray.push(parseInt(item.pin, 10));
  });
  return pinsArray;
};

export const getRegionsArrayFromReports = function (reports) {
  const regionsArray = [];
  reports.forEach(function (item) {
    if (regionsArray.indexOf(item.region) < 0 && item.region !== "") {
      regionsArray.push(item.region);
    }
  });
  return regionsArray;
};

export const getBusinessArrayFromReports = function (reports) {
  const businessArray = [];
  reports.forEach(function (item) {
    if (businessArray.indexOf(item.business) < 0 && item.business !== "") {
      businessArray.push(item.business);
    }
  });
  return businessArray;
};

export function matchStateToTerm(state, value) {
  return (
    state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
    state.abbr.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}

export const isMatchTitle = (item, searchValue) => {
  const value = searchValue.toLowerCase();
  return item.toLowerCase().indexOf(value) > -1;
};

export const getEventPaths = evt => {
  let path = (evt.composedPath && evt.composedPath()) || evt.path,
    target = evt.target;

  if (path != null) {
    // Safari doesn't include Window, but it should.
    return path.indexOf(window) < 0 ? path.concat(window) : path;
  }

  if (target === window) {
    return [window];
  }

  function getParents(node, memo) {
    memo = memo || [];
    let parentNode = node.parentNode;

    if (!parentNode) {
      return memo;
    } else {
      return getParents(parentNode, memo.concat(parentNode));
    }
  }

  return [target].concat(getParents(target), window);
};

export const getVerticalCoords = elem => {
  let doc = document.documentElement;
  let bodyTop = Math.round(
    (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
  );

  let box = elem.getBoundingClientRect();
  let body = document.body;
  let docEl = document.documentElement;
  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let clientTop = docEl.clientTop || body.clientTop || 0;
  let top = Math.round(box.top + scrollTop - clientTop);

  return bodyTop - top;
};
