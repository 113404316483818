import React, { Component } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import moment from 'moment';
import { getReport, getLables, updateReport } from '../../ajax/AjaxReport';
import { setColor } from '../../store/actions/bgcolor';
import store from '../../store/Store';

const mapStateToProps = (store) => {
  return {
    user: store.user,
    reportsFullList: store.reportsFullList
  }
};

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} params={useParams()} {...props} />;
  };
  return Wrapper;
};

class Report extends Component {
  constructor(props) {
    super(props);
    const username = props.user.firstName + ' ' + props.user.lastName;

    this.state = {
      siteName: '',
      reportedBy: '',
      createDate: '',
      location: '',
      person: '',
      company: '',
      categories: [],
      description: '',
      isPositive: '',
      photos: [],
      labels: [],
      originalLabels: [],
      reportLabelList: [],
      isUpdating: true,
      canUpdate: false,
      username: username.trim(),
      //
      deletedDate: '',
      status: '',
      comments: '',
      comments_by: '',
      sign_off: '',
      comments_datetime: ''
    };

    store.dispatch(setColor({ color: 'blueLightBg' }));
  };

  // call for first load
  componentDidMount() {
    this.getReportById(this.props.params.reportId);

    getLables().then(reportLabelsData => {
      const labels = reportLabelsData._embedded.label;

      this.setState({ reportLabelList: labels });
    });
  };

  // call if user changes
  componentWillReceiveProps(nextProps) {
    this.getReportById(nextProps.params.reportId);
  };

  getReportById = (id) => {
    getReport(id).then((reportData) => {
      if (typeof reportData.id !== 'undefined') {
        this.processReportData(reportData);
      } else {
        this.props.navigate('/not-found');
      }
    });
  };

  goToList = () => {
    this.props.navigate('/dashboard/reports');
  };

  getDateTiem = () => {
    return new Date().toISOString().slice(0, 19).replace('T', ' ');
  }

  processReportData = (reportData) => {
    if (Object.keys(reportData.labels).length > 0) {
      const labels = [];

      Object.keys(reportData.labels).forEach((key) => {
        labels.push(parseInt(key, 10));
      });

      reportData.labels = labels;
    }
    reportData.isUpdating = false;
    reportData.canUpdate = false;

    this.setState(reportData);
  };

  handleLabelCheckbox = (event, targetSpecific) => {
    this.setState({
      canUpdate: true
    });
    const target = targetSpecific ? targetSpecific : event.target;
    const name = parseInt(target.name, 10);
    const labels = this.state.labels;

    if (target.checked) {
      labels.push(name);
    } else {
      var index = labels.indexOf(name);
      labels.splice(index, 1);
    }

    this.setState({ labels });
  };

  changeComments = (event, targetSpecific) => {
    const target = targetSpecific ? targetSpecific : event.target;

    this.setState({
      canUpdate: true,
      comments: target.value,
      comments_by: target.value.length > 0 ? this.state.username : null,
      comments_datetime: target.value.length > 0 ? this.getDateTiem() : null,
    });
  };

  handleSignOff = (event, targetSpecific) => {
    const target = targetSpecific ? targetSpecific : event.target;

    this.setState({
      canUpdate: true,
      status: target.checked ? 'closed' : 'open',
      sign_off: target.checked ? this.state.username : null,
    });
  };

  updateReport = () => {
    this.setState({
      isUpdating: true
    });

    const reportData = {
      labels: this.state.labels,
      comments: this.state.comments,
      comments_by: this.state.comments_by,
      comments_datetime: this.state.comments_datetime,
      sign_off: this.state.sign_off,
      status: this.state.status
    };

    updateReport(reportData, this.props.params.reportId).then(reportData => {
      if (typeof reportData.id !== 'undefined') {
        this.processReportData(reportData);
      } else {
        this.props.navigate('/not-found');
      }
    });
  };

  render() {
    let reportDate;
    if (this.state.createDate === '') {
      reportDate = new Date();
    } else {
      const t = this.state.createDate.split(/[- :]/);
      reportDate = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
    }

    const prev = this.state.prevId ? this.state.prevId : '';
    const next = this.state.nextId ? this.state.nextId : '';
    const detailsClass = this.state.isUpdating ? 'reportDetail is-updating' : 'reportDetail';
    const signOffUser = this.state.sign_off ? this.state.sign_off : this.state.username;

    return (
      <div>
        <div id="reportHeader">
          <div className="listNav">
            <Link to={`/dashboard/reports/${prev}`} className={`prev ${prev ? 'active' : ''}`}>Previous</Link>
            <Link to={`/dashboard/reports/${next}`} className={`next ${next ? 'active' : ''}`}>Next</Link>
          </div>
        </div>
        <div className={detailsClass} style={{ borderLeftColor: parseInt(this.state.isPositive, 10) === 1 ? '#bebe32' : '#96055a' }}>
          <h2 className="floatLeft">{this.state.siteName}</h2>
          <div className="floatRight">
            <button className="closeButton" onClick={this.goToList}></button>
          </div>
          <div className="form">
            <table className="clear">
              <tbody>
                <tr>
                  <td>Reported by</td>
                  <td>{this.state.reportedBy}</td>
                </tr>
                <tr>
                  <td>Time</td>
                  <td>{moment.utc(reportDate).format('Do MMMM YYYY, H:mm')}</td>
                </tr>
                <tr>
                  <td>Location of observation</td>
                  <td>{this.state.location}</td>
                </tr>
                <tr>
                  <td>Person observed</td>
                  <td>{this.state.person}</td>
                </tr>
                <tr>
                  <td>Company of person observed</td>
                  <td>{this.state.company}</td>
                </tr>
                <tr>
                  <td>Observation category</td>
                  <td className="noPadding categoryTd">
                    {this.state.categories.map(category => (
                      <div key={category}>
                        {category}
                      </div>
                    ))}
                  </td>
                </tr>
                {parseInt(this.state.isPositive, 10) === 0 &&
                  <tr>
                    <td>Observation hazard</td>
                    <td className="noPadding hazardTd">
                      {this.state.hazards.map(hazard => (
                        <div key={hazard}>
                          {hazard}
                        </div>
                      ))}
                    </td>
                  </tr>
                }
                <tr>
                  <td>Observation / idea</td>
                  <td>{this.state.description}</td>
                </tr>
                <tr>
                  <td>Photo</td>
                  <td>
                    {this.state.photos.map(photo => (
                      <div key={photo} className="imgContainer">
                        <a href={photo} target="_blank" rel="noreferrer">
                          <img src={photo} alt={this.state.location} />
                        </a>
                      </div>
                    ))}
                  </td>
                </tr>
                {
                  this.state.reportLabelList.length > 0 ?
                    <tr>
                      <td>Labels</td>
                      <td className='noPadding hazardTd'>
                        {this.state.reportLabelList.map(item => (
                          <div key={item.id} className="reportLabel">
                            <label htmlFor={item.id}>
                              <span>{item.label}</span>
                              <input
                                type="checkbox"
                                name={item.id}
                                id={item.id}
                                onChange={this.handleLabelCheckbox}
                                checked={this.state.labels.find((ch) => ch === item.id)}
                              />
                              <span></span>
                            </label>
                          </div>
                        ))}
                      </td>
                    </tr> : ''
                }
                <tr>
                  <td colspan="2" align="center">
                    <h3>Report Comments &amp; Sign off</h3>
                  </td>
                </tr>
                <tr>
                  <td>Comments</td>
                  <td>
                    <span className="formRow isFull">
                      <textarea id="reportComments" className="reportTextarea noLabel" value={this.state.comments} rows={4} onChange={this.changeComments} />
                    </span>
                  </td>
                </tr>
                {
                  this.state.comments_by ?
                    <tr>
                      <td>Comments by</td>
                      <td>
                        {this.state.comments_by}
                      </td>
                    </tr> : ''
                }
                <tr>
                  <td>Sign Off</td>
                  <td className="noPadding hazardTd">
                    <div className="reportLabel">
                      <label htmlFor="signOffInput">
                        <span>{this.state.status === 'closed' ? 'Report closed by ' + signOffUser : 'Report open'}</span>
                        <input
                          type="checkbox"
                          name="signoff"
                          id="signOffInput"
                          onChange={this.handleSignOff}
                          checked={this.state.status === 'closed'}
                        />
                        <span></span>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="reportUpdate clear">
            <button
              onClick={this.updateReport}
              className="actionButton"
              disabled={!this.state.canUpdate}
            >Update</button>
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(connect(mapStateToProps, null)(Report));
