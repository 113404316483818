import {apiURL} from './api';
import { logoutUser } from './AjaxLogout';
import store from '../store/Store';
const url = apiURL + "user"

//save user
export const saveUser = (data) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => response.json());
}

//update user
export const updateUser = (data, id) => {
  return fetch(url + '/' +id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => response.json());
}

//update some user info
export const updateUserField = (data, id) => {
  return fetch(url + '/' + id, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => response.json());
}

//delete user
export const deleteUser = (id) => {
  return fetch(url + '/' +id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => response.status);
}

//get user
export const getUser = (id, state) => {
  return fetch(url + '/' +id, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    alert('An error occurred trying to access the user information.');
    return {};
  })
  .catch((err) => {
    console.log('Error getting user');
    console.error(err);
  });
}

//get user
export const getUsers = (urlParameters) => {
  let getUrl = url + '?page=' + urlParameters.page + '&excludeUser=' + store.getState().user.id;
  getUrl = typeof urlParameters.pageSize === "undefined" || urlParameters.pageSize < 1 ? getUrl : getUrl + '&pageSize=' + urlParameters.pageSize;
  getUrl = typeof urlParameters.searchValue === "undefined" || urlParameters.searchValue === '' ? getUrl : getUrl + '&search=' + urlParameters.searchValue ;
  getUrl = typeof urlParameters.userRole !== "undefined" ? getUrl + '&userRole=' + urlParameters.userRole : getUrl;
  return fetch(getUrl, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    alert('An error occurred trying to access the user information.');
    return {};
  })
  .catch((err) => {
    console.log('Error getting users');
    console.error(err);
  });
}
