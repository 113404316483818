import React, { Component } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (store) => {
  return {
    user: store.user,
  }
};

const _repeat = (string, n) => {
  let result = ''
  if (!string || n < 1 || n > Number.MAX_SAFE_INTEGER) {
    return result
  }
  // Leverage the exponentiation by squaring algorithm for a faster repeat.
  // See https://en.wikipedia.org/wiki/Exponentiation_by_squaring for more details.
  do {
    if (n % 2) {
      result += string
    }
    n = Math.floor(n / 2)
    if (n) {
      string += string
    }
  } while (n)

  return result
};

const zeroFill = (n) => `${_repeat('0', 6 - String(n).length)}${n}`;

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} location={useLocation()} params={useParams()} {...props} />;
  };

  return Wrapper;
};

class Sublist extends Component {
  constructor(props) {
    super();
    this.state = {
      active: props.activeFilter,
      lastTooltipId: ''
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ active: nextProps.activeFilter });
  };

  toggleTooltip = (id) => {
    if (this.state.lastTooltipId !== '') this.hideTooltip(this.state.lastTooltipId);
    const tooltip = document.getElementById('tooltip-' + id);
    tooltip.className += ' visible';
    this.setState({ lastTooltipId: id })
  };

  hideTooltip = (id) => {
    const tooltip = document.getElementById('tooltip-' + id);
    if (tooltip) tooltip.classList.remove('visible');
  };

  archiveSite = (pin) => {
    this.props.archiveSite(pin, 1);
  };

  unArchiveSite = (pin) => {
    this.props.archiveSite(pin, 0);
  };

  goToSite = (pin) => {
    this.props.navigate('/dashboard/sites/' + pin);
  };

  _renderActiveActions = (sitePin) => {
    return (
      <div className="floatRight" style={{ position: 'relative' }}>
        {parseInt(this.props.user.userRole, 10) < 1 && <button className="asLink" onClick={() => this.goToSite(sitePin)}>View site details</button>}
        {parseInt(this.props.user.userRole, 10) > 0 && <button className="asLink" onClick={() => this.goToSite(sitePin)}>Edit</button>}
        {parseInt(this.props.user.userRole, 10) > 0 && <button className="asLink" onClick={() => this.toggleTooltip(sitePin)}>Archive</button>}
        <div className="actionTooltip" id={`tooltip-${sitePin}`}>
          <p>Are you sure you would like to archive this site</p>
          <button onClick={() => this.archiveSite(sitePin)}>Yes</button>
          <button onClick={() => this.hideTooltip(sitePin)}>No</button>
        </div>
      </div>
    )
  };

  _renderArchivedActions = (sitePin) => {
    return (
      <div className="floatRight" style={{ position: 'relative' }}>
        {parseInt(this.props.user.userRole, 10) > 0 && <button className="asLink" onClick={() => this.toggleTooltip(sitePin)}>Unarchive</button>}
        <div className="actionTooltip" id={`tooltip-${sitePin}`}>
          <p>Are you sure you would like to unarchive this site</p>
          <button onClick={() => this.unArchiveSite(sitePin)}>Yes</button>
          <button onClick={() => this.hideTooltip(sitePin)}>No</button>
        </div>
      </div>
    )
  };

  _renderSubList = () => {
    return (
      this.props.sites.map(site => (
        <tr key={site.pin} className="listRow">
          <td className={`wideTd ${this.state.active === 1 ? 'transparentFont' : ''}`}>{site.name}</td>
          <td className="narrowTd transparentFont">{zeroFill(site.pin)}</td>
          <td>
            {parseInt(this.state.active, 10) === 0 ? this._renderActiveActions(site.pin) : this._renderArchivedActions(site.pin)}
          </td>
        </tr>
      ))
    )
  };

  render() {
    return (
      <table>
        <tbody>
          {this._renderSubList()}
        </tbody>
      </table>
    )
  };
}

export default withParams(connect(mapStateToProps, null)(Sublist));
