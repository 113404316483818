import React, { Component } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import LoadingScreenLoggedIn from '../utils/LoadingScreenLoggedIn';
import LoggedInState from '../../LoggedInState';
import Sublist from './Sublist';
import UserHeader from './UserHeader';
import Paginator from '../utils/Pagination';
import { getUsers, deleteUser } from '../../ajax/AjaxUser';
import { saveUsersPerPage, savePageNumber, saveListState, saveUserSearchValue } from '../../store/actions/userSettings';
import { setColor } from '../../store/actions/bgcolor';
import store from '../../store/Store';

const mapStateToProps = (store) => {
  return {
    user: store.user,
    userSettings: store.userSettings
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUsersPerPage: (usersPerPage) => dispatch(saveUsersPerPage(usersPerPage)),
    savePageNumber: (pageNumber) => dispatch(savePageNumber(pageNumber)),
    saveListState: (listState) => dispatch(saveListState(listState)),
    saveUserSearchValue: (userSearchValue) => dispatch(saveUserSearchValue(userSearchValue)),
  }
};

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} location={useLocation()} params={useParams()} {...props} />;
  };

  return Wrapper;
};

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentPage: 1,
      userRole: 4,
      listLoader: true
    };

    store.dispatch(setColor({ color: 'blueDarkBg' }));
  };

  componentDidMount() {
    if (this.props.user.userRole < 2) {
      this.props.navigate('/dashboard/reports');
    }

    this.resetSettings();
    this.getUsersList(
      1,
      this.props.userSettings.usersPerPage
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.userId !== this.props.params.userId) {
      this.getUsersList(
        this.props.userSettings.pageNumber,
        this.props.userSettings.usersPerPage,
        this.props.userSettings.userSearchValue
      );
    }
  };

  // reset some user settings
  resetSettings = () => {
    this.props.savePageNumber(1);
    this.props.saveUserSearchValue('');
  };

  // get users from a database
  getUsersList = (page, pageSize, searchValue, userRole) => {
    this.setState({ listLoader: true });

    // get filtered users list
    getUsers({ page: page, pageSize: pageSize, searchValue: searchValue, userRole: userRole }).then((usersData) => {
      if (usersData && usersData._embedded && usersData._embedded.user) {
        const totalItems = parseInt(usersData.total_items, 10);
        let pageSize = parseInt(this.props.userSettings.usersPerPage, 10);

        if (pageSize > totalItems) {
          if (totalItems < 50 && totalItems <= 10) {
            pageSize = 10;
          } else if (totalItems < 100 && totalItems <= 50) {
            pageSize = 50;
          } else if (totalItems < 250 && totalItems <= 100) {
            pageSize = 100;
          }

          this.props.saveUsersPerPage(pageSize);
        }
        this.setState({
          users: usersData._embedded.user,
          isLoading: false,
          pageCount: usersData.page_count,
          pageSize: pageSize,
          totalItems: totalItems,
          currentPage: usersData.page,
          listLoader: false
        });
      }
    });
  };

  // update users list after adding new user
  updateUsersList = () => {
    this.getUsersList(
      this.props.userSettings.pageNumber,
      this.props.userSettings.usersPerPage,
      this.props.userSettings.userSearchValue
    );
  };

  // change user role - for approved users
  changeUserRoleFilter = (value) => {
    this.getUsersList(
      1,
      this.props.userSettings.usersPerPage,
      '',
      value
    );
    this.resetSettings();
    this.setState({
      userRole: value
    });
  };

  // change page
  changePage = (page) => {
    this.getUsersList(
      page,
      this.props.userSettings.usersPerPage
    );
    this.props.savePageNumber(page);
    this.setState({
      currentPage: page
    });
  };

  // change page size
  changePageSize = (pageSize) => {
    this.getUsersList(
      1,
      pageSize
    );
    this.props.saveUsersPerPage(pageSize);
    this.setState({
      pageSize: pageSize
    });
  };

  // seacrh users on the fly
  searchUsers = (searchValue) => {
    this.props.saveUserSearchValue(searchValue);
    this.getUsersList(
      1,
      this.props.userSettings.usersPerPage,
      searchValue
    );
  };

  deleteUser = (id) => {
    deleteUser(id).then((data) => {
      if (data === 204) {
        this.getUsersList(
          this.props.userSettings.pageNumber,
          this.props.userSettings.usersPerPage,
          this.props.userSettings.userSearchValue
        );
      } else {
        this.setState({
          error: 'Server error. Please try again later.'
        });
      }
    });
  };

  _renderLoading = () => {
    return (<LoadingScreenLoggedIn />);
  };

  // Render list of users
  _renderList = () => {
    const users = this.state.users;
    return (
      <div>
        <div className={this.state.listLoader ? 'list is-loading' : 'list'} id="userList">
          <Sublist users={users} deleteUser={this.deleteUser} />
        </div>
        {<Paginator
          pageCount={this.state.pageCount}
          currentPage={this.state.currentPage}
          pageSize={this.state.pageSize}
          totalItems={this.state.totalItems}
          changePage={this.changePage}
          changePageSize={this.changePageSize} />}
      </div>
    )
  };

  _renderContent = () => {
    return (
      <div>
        {
          this.props.children ? React.cloneElement(this.props.children, { updateUsersList: this.updateUsersList }) : this._renderList()
        }
      </div>
    )
  };

  render() {
    const isNew = this.props.location.pathname.indexOf('new-user') >= 0;

    return (
      <DocumentTitle title='Wates Stop / Go Observation App Dashboard: Users'>
        <LoggedInState>
          <UserHeader
            view={this.props.children ? 'detail' : 'list'}
            isNew={isNew}
            userId={this.props.params.userId}
            userRole={this.state.userRole}
            searchUsers={this.searchUsers}
            changeUserRoleFilter={this.changeUserRoleFilter} />
          {this.state.isLoading ? this._renderLoading() : this._renderContent()}
        </LoggedInState>
      </DocumentTitle>
    )
  };
}

export default withParams(connect(mapStateToProps, mapDispatchToProps)(Users));
