import { SET_COLOR } from '../actions/bgcolor';

const initialState = {
	color: ''
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SET_COLOR:
			return Object.assign({}, state, action.color);
		default: return state;
	}
}
