import React from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from './components/parts/Footer';
import Header from './components/parts/Header';
import Login from './components/login/Login';

import Navigation from './components/parts/Navigation';

const mapStateToProps = (store) => {
  return {
    bgcolor: store.bgcolor,
    user: store.user
  }
}

const BgColor = (props) => {
  const bgClass = props.bgcolor.color || 'blueBg';
  const isLoggedIn = props.user.id;
  const { pathname } = useLocation();

  // Home page check
  setTimeout(() => {
    if (pathname === '/') {
      if (isLoggedIn && isLoggedIn > -1) {
        window.location.hash = '#/dashboard/reports';
      }
    }
  }, 100);

  const _renderLoggedIn = () => {
    return (
      <div className="container containerLoggedIn">
        <Header />
        <div className="content">
          <Navigation />
          <Outlet />
        </div>
      </div>
    )
  };

  const _renderNotFound = () => {
    return (
      <Outlet />
    )
  };

  const _renderLoggedOut = () => {
    return (
      <div className="container containerLoggedOut">
        <Header />
        <Login />
      </div>
    )
  };

  return (
    <div className={`${bgClass} bgAnimate colorWrapper ${isLoggedIn ? "loggedIn" : "loggedOut"}`}>
      {
        isLoggedIn ? pathname === '/not-found' ? _renderNotFound() : _renderLoggedIn() : _renderLoggedOut()
      }
      <Footer />
    </div>
  );
}

export default connect(mapStateToProps, null)(BgColor);
