/* eslint-disable no-throw-literal */
import {apiURL} from './api';
const url = apiURL + "pswd-request";

export const sendPswdRequest = (data) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => response.json());
}

export const getByToken = (token) => {
  return fetch(url + '/' + token, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => response.json())
  .then((data) => {
    if (data.status === 500){
      throw "Server Error: Please, try again";
    }
    if (data.status === 404){
      throw "Your reset token has expired";
    }
    return data;
  });
}

//delete user
export const deleteToken = (id) => {
  return fetch(url + '/' +id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => {
    return response.ok;
  })
  .then((data) => {
    if (data){
      if (data.status === 500){
        throw "Server Error: Please, try again";
      }
      if (data.status === 404){
        throw "Your token has expired";
      }
    }
    return data;
  });
}

//update some user info
export const updatePassword = (password, id) => {
  return fetch(url + '/' + id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({'password': password})
  })
  .then((response) => response.json());
}
