import { SAVE_USER, CLEAN_USER_STORE } from '../actions/user';

const initialState = {
	id: null,
	firstName: '',
	lastName: '',
	jobTitle: '',
  company: '',
	email: '',
	phoneNumber: null,
	userRole: 0,
	isApproved: null,
  createDate: ''
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SAVE_USER:
			return Object.assign({}, state, action.user);
		case CLEAN_USER_STORE:
			return Object.assign({}, initialState);
		default: return state;
	}
}
