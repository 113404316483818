import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input/lib/Component';

const mapStateToProps = (store) => {
  return {
    user: store.user,
    siteSettings: store.siteSettings,
    showResetSearch: false
  }
};

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} {...props} />;
  };

  return Wrapper;
};

class SiteHeader extends Component {
  constructor(props) {
    super();
    this.state = {
      active: props.activeFilter,
      searchValue: props.siteSettings.searchValue,
      view: props.view
    }
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      active: nextProps.activeFilter,
      view: nextProps.view
    });
  };

  /**
   * Search value cleanup before unmounting the component.
   */
  // componentWillUnmount() {
  //   this.resetSearch();
  // }

  changeActive = (activeValue) => {
    this.resetSearch();
    this.props.changeFilter(activeValue);
    if (this.state.view !== 'list') {
      this.props.navigate('/dashboard/sites');
    }
  }

  search = (event) => {
    const { value } = event.target;
    this.setState({
      searchValue: value,
      showResetSearch: true
    });

    if (value.length > 2) {
      this.props.searchSites(value);
    } else {
      this.props.searchSites('');
    }
  };

  resetSearch = () => {
    this.setState({
      searchValue: '',
      showResetSearch: false
    });
    this.props.searchSites('');
  };

  _renderSearchRow = () => {
    return (
      <div className="searchRow">
        <div className="searchBar clear">
          <DebounceInput
            type="text"
            name="search"
            id="search"
            className="searchInput"
            placeholder="Search"
            value={this.state.searchValue}
            onChange={this.search}
            minLength={2}
            debounceTimeout={500} />
          <button className={`cancelSearch ${this.state.showResetSearch ? 'visible' : ''}`} onClick={this.resetSearch}></button>
        </div>
        <div className="columnsLabels">
          <ul>
            <li>Contract Number</li>
            <li>Actions</li>
          </ul>
        </div>
      </div>
    )
  };

  render() {
    const active = this.state.active;

    return (
      <div id="siteHeader" className={active === 0 ? 'activeHeader' : 'archivedHeader'}>
        <div className="filterList">
          <button className={active === 0 ? 'active' : ''} onClick={() => this.changeActive(0)}>Active</button>
          <button className={active === 1 ? 'active' : ''} onClick={() => this.changeActive(1)}>Archived</button>
        </div>
        {!this.props.isNew && parseInt(this.props.user.userRole, 10) > 0 &&
          <div className="floatRight">
            <Link to="/dashboard/sites/new-site" className="actionButton">Add new site</Link>
          </div>
        }
        {this.props.view === 'list' ? this._renderSearchRow() : null}
      </div>
    );
  }
}

export default withParams(connect(mapStateToProps, null)(SiteHeader));
