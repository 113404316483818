import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavButton = (props) => {
  const location = useLocation();
  let active = location.pathname.includes(props.goTo)

  if (location.pathname === '/dashboard/profile' && props.goTo === 'dashboard/users') {
    active = true
  }

  return (
    <li className="navBtn">
      <Link
        to={props.goTo}
        id={props.id}
        className={`navLink ${active ? 'active' : ''}`}
        onClick={props.animateLine}>{props.value}</Link>
    </li>
  );
}

export default NavButton;
