import React, { Component } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input/lib/Component';
import { getEventPaths } from '../utils/Helpers';
import { findIndex, includes } from 'lodash';

const mapStateToProps = (store) => {
  return {
    user: store.user,
    userSettings: store.userSettings,
    showResetSearch: false
  }
};

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} location={useLocation()} params={useParams()} {...props} />;
  };

  return Wrapper;
};

class UserHeader extends Component {
  constructor(props) {
    super();
    this.state = {
      searchValue: props.userSettings.userSearchValue,
      view: props.view,
      userRole: parseInt(props.userRole, 10),
      showRoles: false
    }
  };

  componentDidMount() {
    document.addEventListener('click', this._checkClicks);
  };

  /**
   * Search value cleanup before unmounting the component.
   */
  componentWillUnmount() {
    document.removeEventListener('click', this._checkClicks);
  };

  _checkClicks = (evt) => {
    let paths = getEventPaths(evt);
    let checker = true;
    findIndex(paths, function (o) {
      if (o.classList && o.classList.length > 0) {
        if (includes(o.classList.value, 'clickable')) {
          checker = false;
        }
        if (includes(o.classList, 'clickable')) {
          checker = false;
        }
      }
    });

    if (checker) {
      this.setState({
        showRoles: false,
      });
    }
  };

  search = (event) => {
    const { value } = event.target;
    this.setState({
      searchValue: value,
      showResetSearch: true
    });

    if (value.length > 2) {
      this.props.searchUsers(value);
    } else {
      this.props.searchUsers('');
    }
  };

  resetSearch = () => {
    this.setState({
      searchValue: '',
      showResetSearch: false
    });
    this.props.searchUsers('');
  };

  changeUserRole = (event) => {
    const userRole = parseInt(event.target.name.substr(event.target.name.indexOf("-") + 1), 10);
    const role = ['user', 'sitemanager', 'admin', 'super'][userRole];
    this.setState({ userRole, role });
    this.toggleRoles();
    this.props.changeUserRoleFilter(userRole);
  };

  toggleRoles = () => {
    this.setState({ showRoles: !this.state.showRoles });
  };

  _renderSearchRow = () => {
    const role = this.state.userRole;
    const roles = ['Standard users', 'Site managers', 'Admin users', 'Super users', 'All users'];
    const roleLabel = roles[role];

    return (
      <div className="searchRow">
        <div className="searchBar clear">
          <DebounceInput
            type="text"
            name="search"
            id="search"
            className="searchInput"
            placeholder="Search"
            value={this.state.searchValue}
            onChange={this.search}
            minLength={2}
            debounceTimeout={500} />
          <button
            className={`cancelSearch ${this.state.showResetSearch ? 'visible' : ''}`}
            onClick={this.resetSearch}></button>
        </div>

        <div className="columnsLabels">
          <ul>
            {typeof this.props.params.userId === 'undefined' && !this.props.isNew && parseInt(this.props.user.userRole, 10) > 1 &&
              <li>
                <Link to="/dashboard/users/new-user" className="actionButton">Add new user</Link>
              </li>
            }
            <li>
              <button
                onClick={this.toggleRoles}
                className="toggleBtn floatRight clickable">{roleLabel}</button>
              {this.state.showRoles &&
                <div className="filterTooltip clickable">
                  <ul>
                    <li>
                      <label htmlFor="type-all" className={role === 4 ? 'active' : ''}>
                        <span>All users</span>
                        <input
                          type="radio"
                          name="type-4"
                          id="type-all"
                          className={role === 4 ? 'active' : ''}
                          checked={role === 4}
                          onChange={(event) => this.changeUserRole(event)} />
                        <span></span>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="type-standard" className={role === 0 ? 'active' : ''}>
                        <span>Standard users</span>
                        <input
                          type="radio"
                          name="type-0"
                          id="type-standard"
                          className={role === 0 ? 'active' : ''}
                          checked={role === 0}
                          onChange={(event) => this.changeUserRole(event)} />
                        <span></span>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="type-sitemanager" className={role === 1 ? 'active' : ''}>
                        <span>Site managers</span>
                        <input
                          type="radio"
                          name="type-1"
                          id="type-sitemanager"
                          className={role === 1 ? 'active' : ''}
                          checked={role === 1}
                          onChange={(event) => this.changeUserRole(event)} />
                        <span></span>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="type-admin" className={role === 2 ? 'active' : ''}>
                        <span>Admin users</span>
                        <input
                          type="radio"
                          name="type-2"
                          id="type-admin"
                          className={role === 2 ? 'active' : ''}
                          checked={role === 2}
                          onChange={(event) => this.changeUserRole(event)} />
                        <span></span>
                      </label>
                    </li>

                    {this.props.user.userRole > 2 &&
                      <li>
                        <label htmlFor="type-super" className={role === 3 ? 'active' : ''}>
                          <span>Super users</span>
                          <input
                            type="radio"
                            name="type-3"
                            id="type-super"
                            className={role === 3 ? 'active' : ''}
                            checked={role === 3}
                            onChange={(event) => this.changeUserRole(event)} />
                          <span></span>
                        </label>
                      </li>
                    }

                  </ul>
                </div>
              }
            </li>
          </ul>
        </div>
      </div>
    )
  };

  render() {
    return (
      <div id="userHeader">
        {this.props.view === 'list' ? this._renderSearchRow() : null}
      </div>
    );
  }
}

export default withParams(connect(mapStateToProps, null)(UserHeader));
