import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div id='footer'></div>
    );
  }
}

export default Footer;
