import jwt from './jwt';
import user from './user';
import bgcolor from './bgcolor';
import userSettings from './userSettings';
import reportSettings from './reportSettings';
import siteSettings from './siteSettings';

export const jwtReducer = jwt;
export const userReducer = user;
export const bgcolorReducer = bgcolor;
export const userSettingsReducer = userSettings;
export const reportSettingsReducer = reportSettings;
export const siteSettingsReducer = siteSettings;
