import { createStore, combineReducers } from 'redux';
import { loadState } from '../localStorage/Storage';
import {
  jwtReducer,
  userReducer,
  bgcolorReducer,
  userSettingsReducer,
  reportSettingsReducer,
  siteSettingsReducer
} from './reducers';

const persistedState = loadState();

/** Redux **/
const store = createStore(
  combineReducers({
    jwt: jwtReducer,
    user: userReducer,
    bgcolor: bgcolorReducer,
    userSettings: userSettingsReducer,
    reportSettings: reportSettingsReducer,
    siteSettings: siteSettingsReducer
  }),
  persistedState
);

export default store;
