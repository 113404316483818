import './polyfill.js';
import React from 'react';
import ReactDOM from 'react-dom';
import DocumentTitle from 'react-document-title';
import { Provider } from 'react-redux';
import store from './store/Store';
import { saveState } from './localStorage/Storage'
import App from './App';
import './index.css';

store.subscribe(()=>
  saveState(store.getState())
);

ReactDOM.render(
  <DocumentTitle title='Wates Stop / Go Observation App Dashboard'>
    <Provider store={ store }>
      <App />
    </Provider>
  </DocumentTitle>,
  document.getElementById('root')
);
