import { SAVE_SITES_PER_PAGE, SAVE_PAGE_NUMBER, SAVE_SEARCH_VALUE, CLEAN_SITE_SETTINGS_STORE } from '../actions/siteSettings';

const initialState = {
	sitesPerPage: 25,
	siteSearchValue: '',
	pageNumber: 1
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SAVE_SITES_PER_PAGE:
			return Object.assign({}, state, { sitesPerPage: action.sitesPerPage });
		case SAVE_PAGE_NUMBER:
			return Object.assign({}, state, { pageNumber: action.pageNumber });
		case SAVE_SEARCH_VALUE:
			return Object.assign({}, state, { siteSearchValue: action.siteSearchValue });
		case CLEAN_SITE_SETTINGS_STORE:
			return Object.assign({}, initialState);
		default: return state;
	}
}
