export const SAVE_SITES_PER_PAGE = 'SAVE_SITES_PER_PAGE';
export const SAVE_PAGE_NUMBER = 'SAVE_PAGE_NUMBER';
export const SAVE_SEARCH_VALUE = 'SAVE_SEARCH_VALUE';
export const CLEAN_SITE_SETTINGS_STORE = 'CLEAN_SITE_SETTINGS_STORE';

export function saveSitesPerPage(sitesPerPage) {
  return { type: SAVE_SITES_PER_PAGE, sitesPerPage }
}

export function savePageNumber(pageNumber) {
  return { type: SAVE_PAGE_NUMBER, pageNumber }
}

export function saveSiteSearchValue(siteSearchValue) {
  return { type: SAVE_SEARCH_VALUE, siteSearchValue }
}

export function cleanSiteSettingsStore() {
  return { type: CLEAN_SITE_SETTINGS_STORE }
}
