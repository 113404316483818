/* eslint-disable radix, default-case */
import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker from '../utils/DateRangePicker';
import { apiURL } from '../../ajax/api';
import ReportStatus from './ReportCsvUploadStatus';
import { DebounceInput } from 'react-debounce-input/lib/Component';
import { getEventPaths, isMatchTitle } from '../utils/Helpers';
import { getSites as getSiteMaster } from '../../ajax/AjaxSite';
import { getLables } from '../../ajax/AjaxReport';

import { findIndex, includes } from 'lodash';

const mapStateToProps = store => {
  return {
    jwt: store.jwt,
    user: store.user,
    reportSettings: store.reportSettings,
    reportsFullList: store.reportsFullList
  };
};

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} params={useParams()} {...props} />;
  };

  return Wrapper;
};

class ReportHeader extends Component {
  constructor(props) {
    super();
    this.jwt = props.jwt.token;
    this.state = {
      view: props.view,
      searchValue: props.reportSettings.searchValue,
      type: parseInt(props.reportState),
      btnActive: false,
      showDropdown: false,
      showDatepicker: false,
      showResetSearch: false,
      dateRangeOption: null,
      sites: [],
      regions: [],
      business: [],
      originalSites: [],
      originalRegions: [],
      originalBusiness: [],
      searchSite: '',
      searchRegion: '',
      searchBusiness: '',
      showSites: false,
      showRegions: false,
      showBusiness: false,
      regionsToSend: [],
      businessToSend: [],
      sitesToSend: [],
      startRangeDate: '',
      endRangeDate: '',
      showTypes: false,
      showGeneralUploadError: false,
      generalUploadError: 'An error has occurred, please try again.',
      showLabels: false,
      labelList: [],
      reportLabel: 'all',
      optionLabel: 'All Labels',
      resetFilterState: false
    };
  };

  componentWillReceiveProps = nextProps => {
    this.setState({
      view: nextProps.view
    });
  };

  componentDidMount() {
    this.getSiteList();
    document.addEventListener('click', this._checkClicks);

    getLables().then(reportLabelsData => {
      const labels = reportLabelsData._embedded.label;

      this.setState({
        labelList: Object.assign(labels, {})
      });
    });
  };

  /**
   * Search value cleanup before unmounting the component.
   */
  componentWillUnmount() {
    document.removeEventListener('click', this._checkClicks);
  };

  // Get sites
  getSiteList() {
    getSiteMaster({
      'filterBy': 'isArchived',
      'filterValue': 0,
      'page': 1,
      'pageSize': 3000
    }).then(sitesData => {
      const sites = sitesData._embedded.site;

      let regions = sites.map(item => item.region).filter((value, index, self) =>
        index === self.findIndex((t) => (t === value))
      );

      let businesses = sites.map(item => item.business).filter((value, index, self) =>
        index === self.findIndex((t) => (t === value))
      );

      this.setState({
        sites: sites,
        regions: regions,
        business: businesses,
        originalSites: sites,
        originalRegions: regions,
        originalBusiness: businesses
      });
    });
  };

  _checkClicks = evt => {
    let paths = getEventPaths(evt);
    let checker = true;
    findIndex(paths, function (o) {
      if (o.classList && o.classList.length > 0) {
        if (includes(o.classList.value, 'clickable')) {
          checker = false;
        }
        if (includes(o.classList, 'clickable')) {
          checker = false;
        }
      }
    });

    if (checker) {
      this.setState({
        showDatepicker: false,
        showDropdown: false,
        showGeneralUploadError: false,
        showResetSearch: false,
        showTypes: false,
        showSites: false,
        showRegions: false,
        showBusiness: false
      });
    }
  };

  search = event => {
    const { value } = event.target;
    this.setState({
      searchValue: value,
      showResetSearch: true
    });

    if (value.length > 2) {
      this.props.searchReports(value);
    } else {
      this.props.searchReports('');
    }
  };

  resetSearch = () => {
    this.setState({
      searchValue: '',
      showResetSearch: false
    });
    this.props.searchReports('');
  };

  changeDate = (type, value, option) => {
    const intValue = parseInt(value, 10);
    const today = new Date();
    const todayString = moment(today).format('Do MMM');
    let labelText = '';
    let labelValue = '';
    let endDate = '';
    let isChecked = 0;
    let startRangeDate = '';
    let endRangeDate = '';

    if (type === 0) {
      endRangeDate = moment(today).endOf('day').unix();
      switch (intValue) {
        case 60:
          endDate = new Date().setMonth(new Date().getMonth() - 2);
          labelText = 'The last 2 months';
          labelValue = moment(endDate).format('Do MMM') + ' - ' + todayString;
          startRangeDate = moment(endDate).startOf('day').unix();
          break;
        case 30:
          endDate = new Date().setMonth(new Date().getMonth() - 1);
          labelText = 'The last month';
          labelValue = moment(endDate).format('Do MMM') + ' - ' + todayString;
          isChecked = 1;
          startRangeDate = moment(endDate).startOf('day').unix();
          break;
        case 7:
          endDate = new Date().setDate(new Date().getDate() - 7);
          labelValue = moment(endDate).format('Do MMM') + ' - ' + todayString;
          labelText = 'The last week';
          isChecked = 2;
          startRangeDate = moment(endDate).startOf('day').unix();
          break;
      }
    } else {
      endRangeDate = moment(today).endOf('day').unix();
      startRangeDate = moment(today).startOf('day').unix();
      labelText = 'Today';
      labelValue = todayString;
      isChecked = 3;
    }
    this.props.changeDate(type, value);

    this.setState({
      dateRange: labelText + ': ' + labelValue,
      isChecked,
      showDropdown: false,
      resetFilterState: true,
      startRangeDate: startRangeDate,
      endRangeDate: endRangeDate,
      dateRangeOption: option
    });
  };

  changeRange = () => {
    this.setState({
      showDatepicker: false,
      dateRange:
        moment(this.state.startDate).format('Do MMM, YY') +
        ' - ' +
        moment(this.state.endDate).format('Do MMM, YY')
    });

    let startRangeDate = moment(this.state.startDate).startOf('day').unix();
    let endRangeDate = moment(this.state.endDate).endOf('day').unix();

    this.setState({
      resetFilterState: true,
      startRangeDate: startRangeDate,
      endRangeDate: endRangeDate
    });

    this.props.changeDate(2, this.state.startDate, this.state.endDate);
  };

  handleSelect = date => {
    this.setState({
      resetFilterState: true,
      startDate: moment(date.startDate).format('YYYY-MM-DD'),
      endDate: moment(date.endDate).format('YYYY-MM-DD'),
      btnActive: true
    });
  };

  toggleDateRange = () => {
    this.state.showDatepicker
      ? this.setState({ showDatepicker: !this.state.showDatepicker })
      : this.setState({ showDropdown: !this.state.showDropdown });

    this.setState({
      showSites: false,
      showRegions: false,
      showBusiness: false,
      showTypes: false,
      showGeneralUploadError: false,
      showResetSearch: false
    });
  };

  toggleLabels = () => {
    this.setState({
      showSites: false,
      showRegions: false,
      showBusiness: false,
      showTypes: false,
      showDatepicker: false,
      showDropdown: false,
      showGeneralUploadError: false,
      showResetSearch: false,
      showLabels: !this.state.showLabels
    });
  };

  toggleSites = () => {
    this.setState({
      showSites: !this.state.showSites,
      showRegions: false,
      showBusiness: false,
      showTypes: false,
      showDatepicker: false,
      showDropdown: false,
      showGeneralUploadError: false,
      showResetSearch: false,
      showLabels: false
    });
  };

  toggleRegions = () => {
    this.setState({
      showSites: false,
      showRegions: !this.state.showRegions,
      showBusiness: false,
      showTypes: false,
      showDatepicker: false,
      showDropdown: false,
      showGeneralUploadError: false,
      showResetSearch: false,
      showLabels: false
    });
  };

  toggleBusiness = () => {
    this.setState({
      showSites: false,
      showBusiness: !this.state.showBusiness,
      showRegions: false,
      showTypes: false,
      showDatepicker: false,
      showDropdown: false,
      showGeneralUploadError: false,
      showResetSearch: false,
      showLabels: false
    });
  };

  toggleTypes = () => {
    this.setState({
      showTypes: !this.state.showTypes,
      showBusiness: false,
      showRegions: false,
      showDatepicker: false,
      showDropdown: false,
      showGeneralUploadError: false,
      showResetSearch: false
    });
  };

  toggleDatePicker = () => {
    this.setState({
      showDatepicker: !this.state.showDatepicker,
      showDropdown: !this.state.showDropdown,
      btnActive: false
    });
  };

  handleOptionChange = (event, option, label) => {
    const value = event.target.value === 'all' ? 'all' : parseInt(option);

    this.setState({
      resetFilterState: true,
      reportLabel: value,
      optionLabel: label
    });

    this.props.changeReportFilterState(
      this.state.regionsToSend,
      this.state.businessToSend,
      this.state.sitesToSend,
      this.state.startRangeDate,
      this.state.endRangeDate,
      this.state.type,
      value
    );

    this.toggleLabels();
  };

  changeParentAndSave = (event, type) => {
    const { target } = event;
    const name = target.name;
    let regionsToSend = this.state.regionsToSend;
    let businessToSend = this.state.businessToSend;
    let sitesToSend = this.state.sitesToSend;

    if (type === 0) {
      name === 'all' ? regionsToSend = [] : regionsToSend[0] = name;
      this.setState({
        showRegions: false,
        resetFilterState: true,
        regionsToSend
      });
    } else if (type === 1) {
      name === 'all' ? businessToSend = [] : businessToSend[0] = name;
      this.setState({
        showBusiness: false,
        resetFilterState: true,
        businessToSend
      });
    } else {
      name === 'all' ? sitesToSend = [] : sitesToSend[0] = name;
      this.setState({
        showSites: false,
        resetFilterState: true,
        sitesToSend
      });
    }

    this.props.changeReportFilterState(
      regionsToSend,
      businessToSend,
      sitesToSend,
      this.state.startRangeDate,
      this.state.endRangeDate,
      this.state.type,
      this.state.reportLabel
    );
  };

  selectNone = (event) => {
    const buttons = document.getElementsByClassName('downloadChecks');

    for (var i = 0, n = buttons.length; i < n; i++) {
      buttons[i].classList.remove('active');
    }

    if (typeof event !== 'undefined') {
      event.target.classList.add('active');
    }

    const checkboxes = document.getElementsByClassName('siteCheckbox');

    for (var j = 0, m = checkboxes.length; j < m; j++) {
      checkboxes[j].checked = false;
    }

    this.setState({
      sitesToSend: [],
      allowDownload: false
    });
  };

  changeType = event => {
    const type = parseInt(
      event.target.name.substr(event.target.name.indexOf('-') + 1)
    );

    this.setState({
      resetFilterState: true,
      type
    });

    this.toggleTypes();

    this.props.changeReportFilterState(
      this.state.regionsToSend,
      this.state.businessToSend,
      this.state.sitesToSend,
      this.state.startRangeDate,
      this.state.endRangeDate,
      type,
      this.state.reportLabel
    );
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    }, function () {
      this.filterItem(name);
    });
  };

  filterItem(type) {
    let searchValue = '';
    let originalValues = [];

    if (type === 'searchRegion') {
      searchValue = this.state.searchRegion;
      originalValues = this.state.originalRegions;
    } else if (type === 'searchBusiness') {
      searchValue = this.state.searchBusiness;
      originalValues = this.state.originalBusiness;
    } else {
      searchValue = this.state.searchSite;
      originalValues = this.state.originalSites;
    }

    if (searchValue.length > 0) {
      const matchItems = originalValues.filter(item => isMatchTitle(item.name || item, searchValue));
      type === 'searchRegion' ? this.setState({ regions: matchItems }) : type === 'searchBusiness' ? this.setState({ business: matchItems }) : this.setState({ sites: matchItems });
    } else if (searchValue.length === 0) {
      type === 'searchRegion' ? this.setState({ regions: originalValues }) : type === 'searchBusiness' ? this.setState({ business: originalValues }) : this.setState({ sites: originalValues });
    }
  };

  getDropdownLabel = (str) => {
    return str.replace(/\+/g, ' ');
  };

  upload = (event) => {
    event.preventDefault();
    let that = this;

    if (that.refs.form[2].value !== '') {
      let theForm = this.refs.form;
      let postUrl = theForm.action;
      let fd = new FormData();
      let xhr = new XMLHttpRequest();

      fd.append('_token', theForm[0].value);
      fd.append('test', theForm[1].value);
      fd.append('csvUpload', theForm[2].files[0]);

      xhr.open('POST', postUrl, true);

      xhr.onload = function () {
        if (this.status < 400) {
          let resp = JSON.parse(this.response);
          that.setState({
            showGeneralUploadError: false,
            uploadingCsv: false,
            showUploadCsvResult: true,
            uploadCsvResult: resp
          });
        } else {
          /**
           * Dealing with non 200 status
           */
          that.setState({
            uploadingCsv: false,
            showUploadCsvResult: false,
            uploadCsvResult: false,
            showGeneralUploadError: true
          });
        }

        if (
          that.refs &&
          that.refs.form &&
          that.refs.form[1] &&
          that.refs.form[2]
        ) {
          that.refs.form[1].value = '';
          that.refs.form[2].value = '';
        }
      };

      xhr.send(fd);

      that.setState({
        uploadingCsv: true,
        showUploadCsvResult: false,
        showGeneralUploadError: false
      });
    }
  };

  resetFilters = () => {
    this.setState({
      type: 2,
      regionsToSend: [],
      businessToSend: [],
      sitesToSend: [],
      searchSite: '',
      searchRegion: '',
      searchBusiness: '',
      startRangeDate: '',
      endRangeDate: '',
      dateRange: false,
      reportLabel: 'all',
      optionLabel: 'All Labels',
      resetFilterState: false,
      dateRangeOption: null
    });

    this.props.changeReportFilterState(
      [],
      [],
      [],
      '',
      '',
      2,
      'all'
    );
  };

  _renderRegions = () => {
    const allChecked = this.state.regionsToSend.length < 1;
    return (
      <ul>
        <li>
          <label
            htmlFor="AllRegions"
            className={allChecked ? 'active' : ''}>
            <span>All Regions</span>
            <input
              type="radio"
              name="all"
              id="AllRegions"
              checked={allChecked}
              onChange={(event) => this.changeParentAndSave(event, 0)} />
            <span></span>
          </label>
        </li>
        {this.state.regions.map((region) => {
          const regionId = region.replace(/ /g, '+');
          const isChecked = this.state.regionsToSend[0] === regionId;
          return (
            <li key={region}>
              <label
                htmlFor={regionId}
                className={isChecked ? 'active' : ''}>
                <span>{region}</span>
                <input
                  type="radio"
                  name={regionId}
                  id={regionId}
                  checked={isChecked}
                  onChange={(event) => this.changeParentAndSave(event, 0)} />
                <span></span>
              </label>
            </li>
          )
        })}
      </ul>
    )
  };

  _renderBusiness = () => {
    const allChecked = this.state.businessToSend.length < 1;
    return (
      <ul>
        <li>
          <label
            htmlFor="AllBusinesses"
            className={allChecked ? "active" : ""}>
            <span>All Businesses</span>
            <input
              type="radio"
              name="all"
              id="AllBusinesses"
              checked={allChecked}
              onChange={(event) => this.changeParentAndSave(event, 1)} />
            <span></span>
          </label>
        </li>
        {this.state.business.map((business) => {
          const businessId = business.replace(/ /g, '+');
          const isChecked = this.state.businessToSend[0] === businessId;
          return (
            <li key={business}>
              <label
                htmlFor={businessId}
                className={isChecked ? 'active' : ''}>
                <span>{business}</span>
                <input
                  type="radio"
                  name={businessId}
                  id={businessId}
                  checked={isChecked}
                  onChange={(event) => this.changeParentAndSave(event, 1)} />
                <span></span>
              </label>
            </li>
          )
        })}
      </ul>
    )
  };

  _renderSites = () => {
    const allChecked = this.state.sitesToSend.length < 1;
    return (
      <ul>
      <li>
        <label
          htmlFor="AllSites"
          className={allChecked ? 'active' : ''}>
          <span>All Sites</span>
          <input
            type="radio"
            name="all"
            id="AllSites"
            checked={allChecked}
            onChange={(event) => this.changeParentAndSave(event, 2)} />
          <span></span>
        </label>
      </li>
      {this.state.sites.map((site) => {
        const siteId = site.name;
        const isChecked = this.state.sitesToSend[0] === siteId;

        return (
          <li key={site.pin}>
            <label
              htmlFor={siteId}
              className={isChecked ? 'active' : ''}>
              <span>{site.name}</span>
              <input
                type="radio"
                name={siteId}
                id={siteId}
                checked={isChecked}
                onChange={(event) => this.changeParentAndSave(event, 2)} />
              <span></span>
            </label>
          </li>
        )
      })}
      </ul>
    )
  };

  _renderSearchRow = () => {
    return (
      <div className="searchRow">
        <div className="searchBar clear">
          <DebounceInput
            type="text"
            name="search"
            id="search"
            className="searchInput"
            placeholder="Search"
            value={this.state.searchValue}
            onChange={this.search}
            minLength={2}
            debounceTimeout={500}
          />
          <button
            className={`cancelSearch ${this.state.showResetSearch ? 'visible' : ''}`}
            onClick={this.resetSearch}
          />
        </div>

        <div className="floatRight">
          <button
            className="actionButton green"
            onClick={this.resetFilters}
            disabled={!this.state.resetFilterState}
          >Reset Filters</button>
        </div>
      </div>
    );
  };

  render() {
    const reportId = this.props.params.reportId;
    const isChecked = this.state.dateRangeOption;
    let today = new Date();
    today = moment(today).format('YYYY-MM-DD');

    const type = this.state.type;
    const typeLabel =
      this.state.type === 2
        ? 'All types'
        : this.state.type === 1
          ? 'Positive'
          : 'Negative';

    const siteLabel = this.state.sitesToSend.length > 0 ? this.getDropdownLabel(this.state.sitesToSend[0]) : 'All sites';
    const regionsLabel = this.state.regionsToSend.length > 0 ? this.getDropdownLabel(this.state.regionsToSend[0]) : 'All regions';
    const businessLabel = this.state.businessToSend.length > 0 ? this.getDropdownLabel(this.state.businessToSend[0]) : 'All business';

    return (
      <div
        id="reportHeader"
        className={(this.state.view === 'detail' && reportId !== 'undefined') ? 'is-hidden' : ''}>
        {typeof reportId === 'undefined' &&
          !this.props.isDownload && (
            <div>
              <div className={`uploadLoader ${this.state.uploadingCsv ? 'visible' : ''}`}>Uploading...</div>
              <div className={`uploadResult ${this.state.showUploadCsvResult ? 'visible' : ''}`}>
                <ReportStatus status={this.state.uploadCsvResult} />
              </div>
              <div className={`uploadResult ${this.state.showGeneralUploadError ? 'visible' : ''}`}>
                <div className="rowsImported">
                  {this.state.generalUploadError}
                </div>
              </div>

              <div className="reportHeaderBtns fullWidth">
                <div>
                  <Link
                    to="/dashboard/reports/download-report"
                    className="actionButton"
                  >Download report</Link>
                </div>
                {parseInt(this.props.user.userRole, 10) > 1 && (
                  <div>
                    <form
                      method="post"
                      ref="form"
                      encType="multipart/form-data"
                      id="csvUploadForm"
                      action={apiURL + 'upload-report'}>
                      <input
                        type="hidden"
                        name="_token"
                        value={this.jwt}
                      />
                      <input
                        type="hidden"
                        name="test"
                        id="test"
                        value="testing"
                      />
                      <input
                        accept=".csv"
                        className="hiddenUpload"
                        onChange={this.upload}
                        type="file"
                        name="csvUpload"
                        id="csvUpload"
                      />
                      <label
                        htmlFor="csvUpload"
                        className="uploadBtn actionButton yellow">Upload CSV</label>
                      <input
                        type="submit"
                        className="hiddenUpload"
                        value="submit"
                      />
                    </form>
                  </div>
                )}
                {parseInt(this.props.user.userRole, 10) > 1 && (
                  <div>
                    <button
                      className="actionButton red"
                      onClick={this.props.toggleDeleteTd}
                    >{!this.props.showDeleteTd ? 'Delete reports' : 'Cancel'}</button>
                  </div>
                )}
              </div>

              <div className="filterRow fullWidth clear">
                <div className="filterContainer">
                <div className="columnsLabels">
                    <ul id="dateRangeList">
                      <li>
                        <button
                          className="selectButton clickable"
                          onClick={this.toggleDateRange}
                        >{this.state.dateRange ? this.state.dateRange : "Date range"}</button>
                        <ul className={this.state.showDropdown ? 'visible clickable' : 'clickable'}>
                          <li>
                            <button
                              onClick={() => this.changeDate(0, 60, 0)}
                              className={`${isChecked === 0 ? 'asCheckbox' : ''}`}
                            >The last 2 months</button>
                          </li>
                          <li>
                            <button
                              onClick={() => this.changeDate(0, 30, 1)}
                              className={`${isChecked === 1 ? 'asCheckbox' : ''}`}
                            >The last month</button>
                          </li>
                          <li>
                            <button
                              onClick={() => this.changeDate(0, 7, 2)}
                              className={`${isChecked === 2 ? 'asCheckbox' : ''}`}
                            >The last week</button>
                          </li>
                          <li>
                            <button
                              onClick={() => this.changeDate(1, today, 3)}
                              className={`${isChecked === 3 ? 'asCheckbox' : ''}`}
                            >Today</button>
                          </li>
                          <li>
                            <button
                              className="datePickerButton"
                              onClick={this.toggleDatePicker}
                            >Select a date range</button>
                          </li>
                        </ul>
                        <div className={`datepicker ${this.state.showDatepicker ? 'visible clickable' : 'clickable'}`}>
                          {
                            this.state.showDatepicker &&
                            <div>
                              <DatePicker changes={this.handleSelect} />
                              <div className="doneButton">
                                <button
                                  disabled={!this.state.btnActive}
                                  className={`${this.state.btnActive ? 'active' : ''}`}
                                  onClick={this.changeRange}
                                >Done</button>
                              </div>
                            </div>
                          }
                        </div>
                      </li>
                    </ul>

                    <ul>
                      <li style={{ position: 'relative' }}>
                        <button
                          onClick={this.toggleRegions}
                          className="toggleBtn clickable"
                        >{regionsLabel}</button>
                        {this.state.showRegions &&
                          <div className="limitedDropdown filterTooltip clickable">
                            <input
                              type="text"
                              name="searchRegion"
                              placeholder="Filter by region"
                              value={this.state.searchRegion}
                              onChange={this.handleInputChange} />
                            {this._renderRegions()}
                          </div>
                        }
                      </li>
                    </ul>

                    <ul>
                      <li style={{ position: 'relative' }}>
                        <button
                          onClick={this.toggleBusiness}
                          className="toggleBtn clickable"
                        >{businessLabel}</button>
                        {this.state.showBusiness &&
                          <div className="limitedDropdown filterTooltip clickable">
                            <input
                              type="text"
                              name="searchBusiness"
                              placeholder="Filter by business"
                              value={this.state.searchBusiness}
                              onChange={this.handleInputChange} />
                            {this._renderBusiness()}
                          </div>
                        }
                      </li>
                    </ul>

                    <ul>
                      <li style={{ position: 'relative' }}>
                        <button
                          onClick={this.toggleSites}
                          className="toggleBtn clickable"
                        >{siteLabel}</button>
                        {this.state.showSites &&
                          <div className="limitedDropdown filterTooltip clickable">
                            <input
                              type="text"
                              name="searchSite"
                              placeholder="Filter by site"
                              value={this.state.searchSite}
                              onChange={this.handleInputChange} />
                            {this._renderSites()}
                          </div>
                        }
                      </li>
                    </ul>

                    <ul>
                      <li style={{ position: 'relative' }}>
                        <button
                          onClick={this.toggleLabels}
                          className="toggleBtn clickable"
                        >{this.state.optionLabel}</button>
                        {this.state.showLabels && (
                          <div className="filterTooltip clickable">
                            <ul>
                              <li>
                                <label
                                  htmlFor="all-options"
                                  className={this.state.reportLabel === 'all' ? 'active' : ''}
                                >
                                  <span>All Labels</span>
                                  <input
                                    type="radio"
                                    name="all"
                                    id="all-options"
                                    value="all"
                                    className={this.state.reportLabel === 'all' ? 'active' : ''}
                                    checked={this.state.reportLabel === 'all'}
                                    onChange={(event) => this.handleOptionChange(event, 'all', 'All Labels')}
                                  />
                                  <span />
                                </label>
                              </li>
                              {this.state.labelList.map((option) => {
                                return (
                                  <li key={option.id}>
                                    <label htmlFor={option.id} className={this.state.reportLabel === option.id ? 'active' : ''}>
                                      <span>{option.label}</span>
                                      <input
                                        type="radio"
                                        name={option.name}
                                        id={option.id}
                                        value={option.id}
                                        checked={this.state.reportLabel === option.id}
                                        className={this.state.reportLabel === option.id ? 'active' : ''}
                                        onChange={(event) => this.handleOptionChange(event, option.id, option.label)}
                                      />
                                      <span></span>
                                    </label>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        )}
                      </li>
                    </ul>

                    <ul>
                      <li style={{ position: 'relative' }}>
                        <button
                          onClick={this.toggleTypes}
                          className="toggleBtn clickable"
                        >{typeLabel}</button>
                        {this.state.showTypes && (
                          <div className="filterTooltip clickable">
                            <ul>
                              <li>
                                <label
                                  htmlFor="type-all"
                                  className={type === 2 ? 'active' : ''}
                                >
                                  <span>All types</span>
                                  <input
                                    type="radio"
                                    name="type-2"
                                    id="type-all"
                                    className={type === 2 ? 'active' : ''}
                                    checked={type === 2}
                                    onChange={event => this.changeType(event)}
                                  />
                                  <span />
                                </label>
                              </li>
                              <li>
                                <label
                                  htmlFor="type-negative"
                                  className={type === 0 ? 'active' : ''}
                                >
                                  <span>Negative</span>
                                  <input
                                    type="radio"
                                    name="type-0"
                                    id="type-negative"
                                    className={type === 0 ? 'active' : ''}
                                    checked={type === 0}
                                    onChange={event => this.changeType(event)}
                                  />
                                  <span />
                                </label>
                              </li>
                              <li>
                                <label
                                  htmlFor="type-positive"
                                  className={type === 1 ? 'active' : ''}
                                >
                                  <span>Positive</span>
                                  <input
                                    type="radio"
                                    name="type-1"
                                    id="type-positive"
                                    className={type === 1 ? 'active' : ''}
                                    checked={type === 1}
                                    onChange={event => this.changeType(event)}
                                  />
                                  <span />
                                </label>
                              </li>
                            </ul>
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        {this.props.view === 'list' ? this._renderSearchRow() : null}
      </div>
    );
  }
}

export default withParams(connect(mapStateToProps, null)(ReportHeader));
