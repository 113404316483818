import {apiURL} from './api';
import { logoutUser } from './AjaxLogout';
const url = apiURL + "report"

//get user
export const getReport = (id) => {
  return fetch(url + '/' +id, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    alert('An error occurred trying to access the report information.');
    return {};
  })
  .catch((err) => {
    console.log('Error getting report');
    console.error(err);
  });
}

//get user
export const getReports = (urlParameters) => {
  let getUrl = url + '?page=' + urlParameters.page;
  getUrl = typeof urlParameters.pageSize === "undefined" || urlParameters.pageSize < 1 ? getUrl : getUrl + '&pageSize=' + urlParameters.pageSize;
  getUrl = typeof urlParameters.searchValue === "undefined" || urlParameters.searchValue === '' ? getUrl : getUrl + '&search=' + urlParameters.searchValue;
  getUrl = typeof urlParameters.reportState === "undefined" || urlParameters.reportState === '' ? getUrl : getUrl + '&reportType=' + urlParameters.reportState;
  getUrl = typeof urlParameters.dateRange === "undefined" || urlParameters.dateRange === '' ? getUrl : getUrl + urlParameters.dateRange;
  getUrl = typeof urlParameters.status === "undefined" || urlParameters.status === '' ? getUrl : getUrl + '&status=' + urlParameters.status;
  getUrl = typeof urlParameters.reportFilters === "undefined" || urlParameters.reportFilters === '' ? getUrl : getUrl + '&filter=' + urlParameters.reportFilters;

  return fetch(getUrl, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    alert('An error occurred trying to access the reports information.');
    return {};
  })
  .catch((err) => {
    console.log('Error getting reports');
    console.error(err);
  });
}

//update report
export const updateReport = (data, id) => {
  return fetch(url + '/' +id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => response.json());
}

//delete report
export const deleteReport = (id) => {
  return fetch(url + '/' +id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => response.status);
}

//delete reports
export const deleteReports = (data) => {
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => response.status);
}

//get user
export const getLables = () => {
  return fetch(apiURL + 'label', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    alert('An error occurred trying to access the report information.');
    return {};
  })
  .catch((err) => {
    console.log('Error getting labels');
    console.error(err);
  });
}
