import React, { Component } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import LoadingScreenLoggedIn from '../utils/LoadingScreenLoggedIn';
import LoggedInState from '../../LoggedInState';
import Sublist from './Sublist';
import SiteHeader from './SiteHeader';
import Paginator from '../utils/Pagination';
import { getSites, updateSiteField } from '../../ajax/AjaxSite';
import { saveSitesPerPage, savePageNumber, saveSiteSearchValue } from '../../store/actions/siteSettings';
import { setColor } from '../../store/actions/bgcolor';
import store from '../../store/Store';

const mapStateToProps = (store) => {
  return {
    user: store.user,
    siteSettings: store.siteSettings
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSitesPerPage: (sitesPerPage) => dispatch(saveSitesPerPage(sitesPerPage)),
    savePageNumber: (pageNumber) => dispatch(savePageNumber(pageNumber)),
    saveSiteSearchValue: (searchValue) => dispatch(saveSiteSearchValue(searchValue)),
  }
};

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} location={useLocation()} params={useParams()} {...props} />;
  };

  return Wrapper;
};

class Sites extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      activeFilter: 0,
      currentPage: 1,
      listLoader: true
    };

    store.dispatch(setColor({ color: 'purpleBg' }));
  };

  componentDidMount() {
    this.resetSettings();
    this.getSitesList(
      0,
      1,
      this.props.siteSettings.sitesPerPage
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.sitePin !== this.props.params.sitePin) {
      this.getSitesList(
        this.state.activeFilter,
        this.props.siteSettings.pageNumber,
        this.props.siteSettings.sitesPerPage,
        this.props.siteSettings.searchValue
      );
    }
  };

  // reset some site settings
  resetSettings = () => {
    this.props.savePageNumber(1);
    this.props.saveSiteSearchValue('');
  };

  // get sites from a database
  getSitesList = (isArchived, page, pageSize, searchValue) => {
    this.setState({
      listLoader: true
    });
    //get filtered sites list
    getSites({ filterBy: "isArchived", filterValue: isArchived, page, pageSize, searchValue }).then((sitesData) => {
      this.setState({
        listLoader: true
      });
      if (sitesData && sitesData._embedded && sitesData._embedded.site) {
        const totalItems = parseInt(sitesData.total_items, 10);
        let pageSize = parseInt(this.props.siteSettings.sitesPerPage, 10);
        if (pageSize > totalItems) {
          if (totalItems < 50 && totalItems <= 10) {
            pageSize = 10;
          } else if (totalItems < 100 && totalItems <= 50) {
            pageSize = 50;
          } else if (totalItems < 250 && totalItems <= 100) {
            pageSize = 100;
          }
          this.props.saveSitesPerPage(pageSize);
        }
        this.setState({
          sites: sitesData._embedded.site,
          isLoading: false,
          pageCount: sitesData.page_count,
          pageSize: pageSize,
          totalItems: totalItems,
          currentPage: sitesData.page,
          listLoader: false
        });
      }
    })
  };

  // update list after adding new site
  updateSitesList = () => {
    this.getSitesList(
      this.state.activeFilter,
      this.props.siteSettings.pageNumber,
      this.props.siteSettings.sitesPerPage,
      this.props.siteSettings.searchValue
    );
  };

  // change filter - active or archived
  changeFilter = (activeValue) => {
    this.getSitesList(
      activeValue,
      1,
      this.props.siteSettings.sitesPerPage
    );
    this.resetSettings();
    this.setState({
      activeFilter: activeValue
    });
  };

  // change page
  changePage = (page) => {
    this.getSitesList(
      this.state.activeFilter,
      page,
      this.props.siteSettings.sitesPerPage
    );
    this.props.savePageNumber(page);
    this.setState({
      currentPage: page
    });
  };

  // change page size
  changePageSize = (pageSize) => {
    this.getSitesList(
      this.state.activeFilter,
      1,
      pageSize
    );
    this.props.saveSitesPerPage(pageSize);
    this.setState({
      pageSize: pageSize
    });
  };

  // seacrh sites on the fly
  searchSites = (searchValue) => {
    this.props.saveSiteSearchValue(searchValue);
    this.getSitesList(
      this.state.activeFilter,
      1,
      this.props.siteSettings.sitesPerPage,
      searchValue
    );
  };

  archiveSite = (pin, isArchived) => {
    updateSiteField({ fieldName: "isArchived", fieldValue: isArchived }, pin).then((data) => {
      if (data.status === 500) {
        this.setState({
          error: 'Server error. Please try again later.'
        });
      } else {
        this.getSitesList(
          this.state.activeFilter,
          this.props.siteSettings.pageNumber,
          this.props.siteSettings.sitesPerPage,
          this.props.siteSettings.searchValue
        );
      }
    });
  };

  _renderLoading = () => {
    return (<LoadingScreenLoggedIn />);
  };

  // Render list of sites
  _renderList = () => {
    const sites = this.state.sites;
    return (
      <div>
        <div className={this.state.listLoader ? 'list is-loading' : 'list'} id="siteList">
          <Sublist sites={sites} activeFilter={this.state.activeFilter} archiveSite={this.archiveSite} />
        </div>
        {<Paginator
          pageCount={this.state.pageCount} currentPage={this.state.currentPage}
          pageSize={this.state.pageSize} totalItems={this.state.totalItems}
          changePage={this.changePage} changePageSize={this.changePageSize} />}
      </div>
    )
  };

  _renderContent = () => {
    return (
      <div>
        {this.props.children ? React.cloneElement(this.props.children, { updateSitesList: this.updateSitesList }) : this._renderList()}
      </div>
    )
  };

  render() {
    const isNew = this.props.location.pathname.indexOf("new-site") >= 0;
    return (
      <DocumentTitle title='Wates Stop / Go Observation App Dashboard: Sites'>
        <LoggedInState>
          <SiteHeader
            view={this.props.children ? 'detail' : 'list'}
            isNew={isNew}
            activeFilter={this.state.activeFilter}
            sitePin={this.props.params.sitePin}
            changeFilter={this.changeFilter}
            searchSites={this.searchSites} />
          {this.state.isLoading ? this._renderLoading() : this._renderContent()}
        </LoggedInState>
      </DocumentTitle>
    )
  };
}

export default withParams(connect(mapStateToProps, mapDispatchToProps)(Sites));
