import React, { Component } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Autocomplete from 'react-autocomplete';
import { Loader, LoaderOptions } from 'google-maps';
import { gmAPIKEY } from '../../ajax/api';
import { getSite, updateSite } from '../../ajax/AjaxSite';
import { updateSitePin } from '../../ajax/AjaxSitePinchange';
import { getCoordinates } from '../../ajax/AjaxMap';
import { errorMessages } from './ErrorMessages';
import { getRegions, styles } from './Regions';
import { getBusiness } from './Business';
import { matchStateToTerm } from '../utils/Helpers';
import { validateString, validateIsEmpty, validateNumber, validateLength } from '../utils/Validator';

const options: LoaderOptions = {};
const loader = new Loader(gmAPIKEY, options);

const mapStateToProps = (store) => {
  return {
    user: store.user,
  }
};

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} params={useParams()} {...props} />;
  };

  return Wrapper;
};

class Site extends Component {
  constructor() {
    super();
    this.state = {
      address: '',
      name: '',
      pin: '',
      region: '',
      business: '',
      isArchived: 0,
      error: '',
      success: '',
      errorElements: [],
      showInfoTooltip: false,
      regionAnimate: false,
      businessAnimate: false,
      saving: false,
      isLoading: true,
      GoogleMap: null,
      Gmarkers: []
    }
  };

  // call for first load
  componentDidMount() {
    this.getSiteByPin(this.props.params.sitePin);
  };

  // call if site changes
  componentWillReceiveProps(nextProps) {
    this.resetMessages();
    if (nextProps.params.sitePin !== this.props.params.sitePin) {
      this.getSiteByPin(nextProps.params.sitePin);
    }
  }
  // get site and update map
  getSiteByPin = (pin) => {
    this.setState({ isLoading: true });
    getSite(pin).then((siteData) => {
      if (typeof siteData.pin !== 'undefined') {
        siteData.isLoading = false;
        this.setState(siteData);

        // update google maps with sites coordinates
        if (!!!siteData.address || siteData.address === '') siteData.address = 'KT22 7SW';
        getCoordinates(siteData.address).then((data) => {
          const _self = this;
          const { lat, lng } = data.results[0].geometry.location;

          loader.load().then(function (google) {
            const el = document.getElementById('map');
            const options = {
              zoom: 5,
              center: { lat: lat, lng: lng },
            };
            const GoogleMap = new google.maps.Map(el, options);
            const Gmarkers = [];

            // remove previos markers
            for (let i = 0; i < Gmarkers.length; i++) {
              Gmarkers[i].setMap(null);
            }

            GoogleMap.panTo({ lat, lng });
            GoogleMap.setZoom(16);
            var marker = new google.maps.Marker({
              position: { lat, lng },
              draggable: true,
              map: GoogleMap
            });

            Gmarkers.push(marker);

            _self.setState({
              GoogleMap: GoogleMap,
              Gmarkers: Gmarkers
            });
          });
        });
      } else {
        this.props.navigate('/not-found');
      }
    });
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  };

  resetMessages = () => {
    this.setState({
      error: '',
      success: '',
      errorElements: []
    });
  };

  // validate form
  validate = () => {
    let isValid = true;
    const { address, name, pin } = this.state;
    let errorElements = this.state.errorElements;
    if (!validateString(name)) {
      errorElements.push('name');
      isValid = false;
    }

    if (!validateIsEmpty(address)) {
      errorElements.push('address');
      isValid = false;
    }
    if (!validateNumber(pin) || !validateLength(6, '' + pin)) {
      errorElements.push('pin');
      isValid = false;
    }
    this.setState({ errorElements });
    return isValid;
  };

  // submit form and update site
  submit = () => {
    this.setState({ saving: true });
    this.resetMessages();
    // validate and save
    const { address, name, pin, region, business, isArchived } = this.state;
    const saveData = { address, name, pin, region, business, isArchived };

    if (this.validate()) {
      updateSite(saveData, this.state.pin).then((data) => {
        if (data.status === 500) {
          this.setState({error: 'Server error. Please try again later.'});
        } else if (data.status === 409) {
          this.setState({error: 'Site with this pin already exists.'});
        } else {
          this.setState({error: ''});
          console.log('Saved site '+this.state.pin+' okay.');
          // this.goNext();
          setTimeout(function() {
            this.setState({ saving: false });
            //this.goToList();
          }.bind(this), 1000);
        }
      });
    }
  };

  // go to sites list
  goToList = () => {
    this.props.navigate('/dashboard/sites');
  };

  //update map view and marker
  updateMap = (event) => {
    const _self = this;
    const value = event.target.value;
    getCoordinates(value).then((data) => {
      const GoogleMap = this.state.GoogleMap;
      const Gmarkers = this.state.Gmarkers;
      const { lat, lng } = data.results[0].geometry.location;

      // remove previos markers
      for (let i = 0; i < Gmarkers.length; i++) {
        Gmarkers[i].setMap(null);
      }

      GoogleMap.panTo({ lat, lng });
      GoogleMap.setZoom(16);

      loader.load().then(function (google) {
        var marker = new google.maps.Marker({
          position: { lat, lng },
          draggable: true,
          map: GoogleMap
        });

        Gmarkers.push(marker);

        _self.setState({
          GoogleMap: GoogleMap,
          Gmarkers: Gmarkers
        });
      });
    })
  };

  // show/hide info tooltip
  toggleInfoTooltip = () => {
    this.setState({ showInfoTooltip: !this.state.showInfoTooltip });
  };

  // blur region input
  regionBlur = () => {
    this.setState({ regionBlured: true });
    this.animateRegionLabel();
  };

  // blur business input
  businessBlur = () => {
    this.setState({ businessBlured: true });
    this.animateBusinessLabel();
  };

  // add animation to region label because of different structure of autocomplete
  animateRegionLabel = () => {
    if (!this.state.regionAnimate) {
      this.setState({ regionAnimate: true });
    } else {
      if (this.state.region === '') {
        this.setState({
          regionAnimate: false,
          regionBlured: false
        });
      }
    }
  };

  //add animation to business label because of different structure of autocomplete
  animateBusinessLabel = () => {
    if (!this.state.businessAnimate) {
      this.setState({ businessAnimate: true });
    } else {
      if (this.state.business === '') {
        this.setState({
          businessAnimate: false,
          businessBlured: false
        });
      }
    }
  };

  // Prompt the user to enter a new site ID.
  promptForSiteId = () => {
    // Check that the user has sufficient privileges (must be at least 2 = admin).
    if (this.props.user.userRole < 2) {
      return window.alert('Sorry, but you\'re not allowed to update the contract number. Please ask a site admin to make this change for you.');
    }

    var validSiteId = new RegExp('^[0-9]{6}$');
    var newId = window.prompt('Enter a new 6-digit contract number for this site:', '');
    var oldId = this.state.pin;

    if (newId === null || newId === '') {
      return; // cancel, or empty value
    }

    if (!validSiteId.test(newId)) {
      this.setState({ error: 'Invalid site ID.' });
      return;
    }

    updateSitePin(newId, oldId).then((data) => {
      if (typeof data.pin === 'undefined') {
        this.setState({ error: 'Sorry, but you can\'t use that contract number. Please try a different one.' });
      } else {
        // Load the site with the new ID. This will repopulate the form 'state'.
        this.props.navigate('/dashboard/sites/' + newId);
      }
    });
  };

  render() {
    const prev = this.state.prevId ? this.state.prevId : '';
    const next = this.state.nextId ? this.state.nextId : '';

    const { address, name, pin } = this.state;
    const btnDisabled = address !== '' && name !== '' && pin !== '' && !this.state.saving ? false : true;
    return (
      <div>
        <div id="siteHeader">
          <div className="listNav">
            <Link to={`/dashboard/sites/${prev}`} className={`prev ${prev ? 'active' : ''}`} >Previous</Link>
            <Link to={`/dashboard/sites/${next}`} className={`next ${next ? 'active' : ''}`}>Next</Link>
          </div>
        </div>
        <div className={`siteCard ${this.state.isLoading ? 'is-loading' : ''}`}>
          <h2 className="floatLeft">
            {this.state.name}
          </h2>
          <div className="floatRight">
            <button className="closeButton" onClick={this.goToList}></button>
          </div>
          <div className="clear mapContainer">
            <div id="map" style={{ width: '100%', height: '270px' }} />
          </div>
          {this.state.error !== '' && <div className="errorRow mainFormError"><p>{this.state.error}</p></div>}
          {this.state.success !== '' && <div className="successRow"><p>{this.state.success}</p></div>}
          <div className="form" id="siteForm">
            <div className="formRow fullWidth noPadding">
              <input type="text" name="address" id="address" style={{ textTransform: 'capitalize' }} data-empty={this.state.address === '' ? 'true' : 'false'}
                className={`${this.state.errorElements.indexOf('address') >= 0 ? 'errorInput' : ''}`}
                onBlur={this.updateMap}
                value={this.state.address} onChange={this.handleChange} />
              {this.state.errorElements.indexOf('address') >= 0 && <span className="errorInputMsg">{errorMessages['address']}</span>}
              <label htmlFor="address"><span className="labelTitle">Site address</span></label>
            </div>
            <div className="formRow">
              <input type="text" name="name" id="name" style={{ textTransform: 'capitalize' }} data-empty={this.state.name === '' ? 'true' : 'false'}
                className={`${this.state.errorElements.indexOf('name') >= 0 ? 'errorInput' : ''}`}
                value={this.state.name} onChange={this.handleChange} />
              {this.state.errorElements.indexOf('name') >= 0 && <span className="errorInputMsg">{errorMessages['name']}</span>}
              <label htmlFor="name"><span className="labelTitle">Site Name</span></label>
            </div>
            <div className="formRow">
              <input type="tel" pattern="[0-9]{6}" name="pin" id="pin" data-empty={this.state.pin === '' ? 'true' : 'false'}
                className={`${this.state.errorElements.indexOf('pin') >= 0 ? 'errorInput' : ''}`}
                value={this.state.pin} onChange={this.handleChange} disabled />
              {this.state.errorElements.indexOf('pin') >= 0 && <span className="errorInputMsg">{errorMessages['pin']}</span>}
              <label htmlFor="pin">
                <span className="labelTitle">Contract Number</span>
                {/*
                  <span className="editSiteIDLink" onClick={this.promptForSiteId}>[EDIT]</span>
                  <span className="infoIcon" onMouseOver={this.toggleInfoTooltip} onMouseOut={this.toggleInfoTooltip}></span>
                  */}
              </label>
              {/*
                <div className={`infoTooltip ${this.state.showInfoTooltip ? "visible" : ""}`}>
                  <p>Click [EDIT] to change the 6 digit contract number for this site</p>
                </div>
                */}
            </div>
            <div className="formRow">
              <Autocomplete
                value={this.state.region}
                wrapperStyle={{ width: '100%' }}
                inputProps={{ name: 'region', id: 'region', className: 'isEmpty', onFocus: this.animateRegionLabel, onBlur: this.regionBlur }}
                items={getRegions()}
                getItemValue={(item) => item.name}
                shouldItemRender={matchStateToTerm}
                onChange={(event, value) => this.setState({ region: value })}
                onSelect={value => this.setState({ region: value })}
                renderItem={(item, isHighlighted) => (
                  <div
                    style={isHighlighted ? styles.highlightedItem : styles.item}
                    key={item.abbr}
                  >{item.name}</div>
                )}
                renderMenu={(items, value, style) => (
                  <div className="autocompleteTooltip" children={items} />
                )}
              />
              <label htmlFor="region" className={this.state.regionAnimate || this.state.region !== '' ? 'animate' : ''}><span className={`labelTitle ${this.state.regionBlured || this.state.region !== '' ? 'transparentFont' : ''}`}>Region</span></label>
            </div>
            <div className="formRow">
              <Autocomplete
                value={this.state.business}
                wrapperStyle={{ width: '100% ' }}
                inputProps={{ name: 'business', id: 'business', className: 'isEmpty', onFocus: this.animateBusinessLabel, onBlur: this.businessBlur }}
                items={getBusiness()}
                getItemValue={(item) => item.name}
                shouldItemRender={matchStateToTerm}
                onChange={(event, value) => this.setState({ business: value })}
                onSelect={value => this.setState({ business: value })}
                renderItem={(item, isHighlighted) => (
                  <div
                    style={isHighlighted ? styles.highlightedItem : styles.item}
                    key={item.abbr}
                  >{item.name}</div>
                )}
                renderMenu={(items, value, style) => (
                  <div className="autocompleteTooltip" children={items} />
                )}
              />
              <label htmlFor="business" className={this.state.businessAnimate || this.state.business !== '' ? 'animate' : ''}><span className={`labelTitle ${this.state.businessBlured || this.state.business !== '' ? 'transparentFont' : ''}`}>Business</span></label>
            </div>
            {this.props.user.userRole > 0 &&
              <div className="buttons">
                <button className={` ${btnDisabled ? '' : 'activeBtn'} btnSuccess`} disabled={btnDisabled} onClick={this.submit}>Save<span className={`${this.state.saving ? '' : 'hidden'}`}> in progress</span></button>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withParams(connect(mapStateToProps, null)(Site));
