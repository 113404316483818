export const SAVE_WAITING_COUNT = 'SAVE_WAITING_COUNT';
export const SAVE_APPROVED_COUNT = 'SAVE_APPROVED_COUNT';
export const SAVE_USERS_PER_PAGE = 'SAVE_USERS_PER_PAGE';
export const SAVE_PAGE_NUMBER = 'SAVE_PAGE_NUMBER';
export const SAVE_SEARCH_VALUE = 'SAVE_SEARCH_VALUE';
export const SAVE_LIST_STATE = 'SAVE_LIST_STATE';
export const CLEAN_USER_SETTINGS_STORE = 'CLEAN_USER_SETTINGS_STORE';

export function saveWaitingCount(waitingCount) {
  return { type: SAVE_WAITING_COUNT, waitingCount }
}

export function saveApprovedCount(approvedCount) {
  return { type: SAVE_APPROVED_COUNT, approvedCount }
}

export function saveUsersPerPage(usersPerPage) {
  return { type: SAVE_USERS_PER_PAGE, usersPerPage }
}

export function savePageNumber(pageNumber) {
  return { type: SAVE_PAGE_NUMBER, pageNumber }
}

export function saveUserSearchValue(userSearchValue) {
  return { type: SAVE_SEARCH_VALUE, userSearchValue }
}

export function saveListState(listState) {
  return { type: SAVE_LIST_STATE, listState }
}

export function cleanUserSettingsStore() {
  return { type: CLEAN_USER_SETTINGS_STORE }
}
