import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Footer from './components/parts/Footer';
import Header from './components/parts/Header';

class NotFound extends Component {
  render() {
    return (
      <div className="blueLightBg bgAnimate colorWrapper">
        <Header />
        <div className="middleContainer">
          <div className="middleBox" id="notFoundBox">
            <div>404</div>
            <p>Sorry, that page does not exist.</p>
            <Link to="/" className="asBtn">Go Home?</Link>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default NotFound;
