/* eslint-disable no-useless-escape */

export const validateName = (name) => {
    const regName = new RegExp("^[A-Za-z ’'-]+$");
		return regName.test(name);
}

export const validateString = (string) => {
    return !/[~`#%\^*=\[\]\\';/{}|\\":<>\?]/g.test(string);
}

export const validateEmail = (email) => {
  const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEmail.test(email);
}

export const validatePhone = (phone) => {
  const regPhone = /^[0-9_+-]+$/;
  return regPhone.test(phone);
}

export const validateNumber = (number) => {
  const regNumber = /^[0-9]+$/;
  return regNumber.test(number);
}

export const validateIsEmpty = (value) => value === "" ? false : true;

export const validateIsEqual = (value1, value2) => value1 === value2 ? true : false;

export const validateMinLength = (length, value) => value.length >= length ? true : false;

export const validateLength = (length, value) => value.length === length ? true : false;
