import React from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { saveUser } from '../../store/actions/user';
import { setJwt } from '../../store/actions/jwt';
import { setColor } from '../../store/actions/bgcolor';
import LoggedOutState from '../../LoggedOutState';
import store from '../../store/Store';


const mapStateToProps = (store) => {
  return {
    user: store.user,
    jwt: store.jwt,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: (user) => dispatch(saveUser(user)),
    setJwt: (jwt) => dispatch(setJwt(jwt)),
    setColor: (color) => dispatch(setColor(color))
  }
};

const Login = (props) => {
  store.dispatch(setColor({ color: 'blueBg' }));

  return (
    <DocumentTitle title='Wates Stop / Go Observation App Dashboard: Login'>
      <LoggedOutState showNav={true}>
        <br /><br /><br />
        <p className="successButton active">Logging in via Azure AD...</p>
      </LoggedOutState>
    </DocumentTitle>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
