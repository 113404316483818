import React, { Component } from 'react';
import Loading from 'react-loading';

class LoadingScreenLoggedIn extends Component {
	render() {
		return (
			<div className="loader-center">
				<Loading type='bars' color='#fff' />
			</div>
		);
	}
}

export default LoadingScreenLoggedIn;
