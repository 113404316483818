// LocalStorage
//import { setLocalUser } from '../../LocalStorage/Storage';

export const SAVE_USER = 'SAVE_USER';
export const CLEAN_USER_STORE = 'CLEAN_USER_STORE';

export function saveUser(user) {
  //setLocalUser(user);
  return { type: SAVE_USER, user }
}

export function cleanUserStore() {
  return { type: CLEAN_USER_STORE }
}
