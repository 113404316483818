import { apiURL } from './api';

const url = apiURL + 'user/logout';

export const logoutUser = () => {
  fetch(url, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then(() => {
    window.location = '/sso/loggedout';
  })
  .catch((err) => {
    console.log('Error logging out.');
    console.error(err);
  });
};
