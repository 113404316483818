import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (store) => {
  return {
		user: store.user,
  }
}

class Sublist extends Component {
   constructor(props) {
    super();
    this.state = {}
  };

  toggleTooltip = (id) => {
    if (this.state.lastTooltipId !== '') this.hideTooltip(this.state.lastTooltipId);
    const tooltip = document.getElementById('tooltip-' + id);
    tooltip.className += ' visible';
    this.setState({ lastTooltipId: id });
  };

  hideTooltip = (id) => {
    const tooltip = document.getElementById('tooltip-' + id);
    if (tooltip) tooltip.classList.remove('visible');
  };

  deleteReport = (id) => {
    this.props.deleteReport(id);
  };

  /**********MANAGE SITES CHECKBOXES*******/
  handleReportCheckbox = (event, targetSpecific) => {
    this.props.handleReportCheckbox(event, targetSpecific);
  };

  render() {
    return (
      <table>
        <tbody>
          {this.props.reports.map(report => (
            <tr key={report.id} className="listRow">
              <td><Link to={`${report.id}`}><span className="rowBorder" style={{borderLeftColor: parseInt(report.isPositive,10) === 1 ? "#bebe32" : "#96055a"}}></span>{report.siteName}</Link></td>
              <td className="viewTd">
                <Link to={`${report.id}`}>
                  <span className="asLink">View</span>
                  {parseInt(report.photosCount,10) > 0 && <span className="pic"></span>}
                </Link>
              </td>
              {this.props.showDeleteTd && <td className="reportCheckboxRow">
                <label htmlFor={report.id}><input type="checkbox" className="reportCheckbox" name={report.id} id={report.id} onChange={this.handleReportCheckbox} /><span></span></label>
              </td>}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default connect(mapStateToProps, null)(Sublist);
