/* eslint-disable default-case */
import React, { Component } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import moment from 'moment';
import LoadingScreenLoggedIn from '../utils/LoadingScreenLoggedIn';
import LoggedInState from '../../LoggedInState';
import Sublist from './Sublist';
import ReportHeader from './ReportHeader';
import Paginator from '../utils/Pagination';
import { getReports, deleteReport, deleteReports } from '../../ajax/AjaxReport';
import {
  convertArrayToMap,
  getVerticalCoords,
} from '../utils/Helpers';
import { saveReportsPerPage, savePageNumber, saveSearchValue } from '../../store/actions/reportSettings';
import { setColor } from '../../store/actions/bgcolor';
import store from '../../store/Store';

const mapStateToProps = (store) => {
  return {
    reportSettings: store.reportSettings
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveReportsPerPage: (reportsPerPage) => dispatch(saveReportsPerPage(reportsPerPage)),
    savePageNumber: (pageNumber) => dispatch(savePageNumber(pageNumber)),
    saveSearchValue: (searchValue) => dispatch(saveSearchValue(searchValue))
  }
};

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} location={useLocation()} params={useParams()} {...props} />;
  };

  return Wrapper;
};

class Reports extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      reports: [],
      reportState: 2,
      reportsToDelete: [],
      allowDelete: false,
      showDeleteTd: false,
      listLoader: true,
      elementFloatAbove: false,
      reportFilters: '',
      dateRange: undefined,
      reportStatus: 'open'
    }

    store.dispatch(setColor({ color: 'blueLightBg' }));
  };

  componentDidMount() {
    this.props.saveSearchValue('');
    this.getReportsList(1, this.props.reportSettings.reportsPerPage, this.state.reportStatus);
    window.addEventListener('scroll', this.handleFloatScroll);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleFloatScroll);
  };

  handleFloatScroll = (event) => {
    if (this.refs.floatArea) {
      if (getVerticalCoords(this.refs.floatArea) > 0) {
        this.setState({
          elementFloatAbove: true
        });
      } else {
        this.setState({
          elementFloatAbove: false
        });
      }
    }
  };

  getReportsList(page, pageSize, status, searchValue, reportState, dateRange, reportFilters) {
    this.setState({
      listLoader: true
    });

    getReports({ page, pageSize, status, searchValue, reportState, dateRange, reportFilters }).then((reportsData) => {
      if (reportsData._embedded) {
        this.setState({
          reports: reportsData._embedded.report,
          isLoading: false,
          pageCount: reportsData.page_count,
          pageSize: reportsData.page_size,
          totalItems: reportsData.total_items,
          currentPage: reportsData.page,
          listLoader: false
        });
      }
    });
  };

  // reset some report settings
  resetSettings = () => {
    this.props.savePageNumber(1);
    this.props.saveSearchValue('');
  };

  // change page
  changePage = (page) => {
    this.getReportsList(
      page,
      this.props.reportSettings.reportsPerPage,
      this.state.reportStatus,
      this.props.reportSettings.searchValue,
      this.state.reportState,
      this.state.dateRange,
      this.state.reportFilters
    );

    this.props.savePageNumber(page);

    this.setState({
      currentPage: page
    });
  };

  // change page size
  changePageSize = (pageSize) => {
    this.getReportsList(
      1,
      pageSize,
      this.state.reportStatus,
      this.props.reportSettings.searchValue,
      this.state.reportState,
      this.state.dateRange,
      this.state.reportFilters
    );

    this.props.saveReportsPerPage(pageSize);

    this.setState({
      pageSize: pageSize
    });
  };

  //set date range
  changeDate = (type, value, valueTo) => {
    switch (type) {
      case (0):
        this.getReportsList(
          1,
          this.props.reportSettings.reportsPerPage,
          this.state.reportStatus,
          this.props.reportSettings.searchValue,
          this.state.reportState,
          '&dateRange=' + value,
          this.state.reportFilters
        );

        this.setState({
          dateRange: '&dateRange=' + value
        });
        break;
      case (1):
        this.getReportsList(
          1,
          this.props.reportSettings.reportsPerPage,
          this.state.reportStatus,
          this.props.reportSettings.searchValue,
          this.state.reportState,
          '&dateFrom=' + value,
          this.state.reportFilters
        );

        this.setState({
          dateRange: '&dateFrom=' + value
        });
        break;
      case (2):
        this.getReportsList(
          1,
          this.props.reportSettings.reportsPerPage,
          this.state.reportStatus,
          this.props.reportSettings.searchValue,
          this.state.reportState,
          '&dateFrom=' + value + '&dateTo=' + valueTo,
          this.state.reportFilters
        );

        this.setState({
          dateRange: '&dateFrom=' + value + '&dateTo=' + valueTo
        });
        break;
    }
  };

  // seacrh reports on the fly
  searchReports = (searchValue) => {
    this.props.saveSearchValue(searchValue);
    this.getReportsList(
      1,
      this.props.reportSettings.reportsPerPage,
      this.state.reportStatus,
      searchValue,
      this.state.reportState,
      this.state.dateRange,
      this.state.reportFilters
    );
  };

  changeReportFilterState = (regions, business, sites, dateRangeStart, dateRangeEnd, type, label) => {
    let filters = [];
    let dateRange = this.state.dateRange;

    if (dateRangeStart === '' && dateRangeEnd === '') {
      dateRange = undefined;
    }

    if (regions && regions.length > 0) {
      regions.forEach(item => {
        filters.push({ key: "site.region", comparitor: "=", value: item });
      });
    }

    if (business && business.length > 0) {
      business.forEach(item => {
        filters.push({ key: "site.business", comparitor: "=", value: item });
      });
    }

    if (sites && sites.length > 0) {
      sites.forEach(item => {
        filters.push({ key: "site.name", comparitor: "=", value: item });
      });
    }

    if (label !== 'all') {
      filters.push({ key: "reportlabel.labelId", comparitor: "=", value: label });
    }

    const filterStr = filters.length > 0 ? encodeURIComponent(JSON.stringify(filters)) : '';

    this.resetSettings();

    this.getReportsList(
      this.state.currentPage,
      this.props.reportSettings.reportsPerPage,
      this.state.reportStatus,
      '',
      type,
      dateRange,
      filterStr
    );

    this.setState({
      dateRange: dateRange,
      reportState: type,
      reportFilters: filterStr
    });
  };

  toggleReportStatus = (status) => {
    this.getReportsList(
      1,
      this.props.reportSettings.reportsPerPage,
      status,
      this.props.reportSettings.searchValue,
      this.state.reportState,
      this.state.dateRange,
      this.state.reportFilters
    );

    this.setState({
      reportStatus: status
    });
  };

  deleteReport = (id) => {
    deleteReport(id).then((data) => {
      if (data === 204) {
        this.getReportsList(
          this.state.currentPage,
          this.props.reportSettings.reportsPerPage,
          this.state.reportStatus,
          '',
          this.state.reportState,
          this.state.dateRange,
          this.state.reportFilters
        );
      } else {
        this.setState({
          error: 'Server error. Please try again later.'
        });
      }
    });
  };

  toggleDeleteTd = () => {
    this.setState({
      showDeleteTd: !this.state.showDeleteTd
    });
  };

  /**********MANAGE SITES CHECKBOXES*******/
  handleReportCheckbox = (event, targetSpecific) => {
    const target = targetSpecific ? targetSpecific : event.target;
    const name = target.name;
    let reportsToDelete = this.state.reportsToDelete;

    if (target.checked) {
      reportsToDelete.push(name);

      this.setState({
        reportsToDelete,
        allowDelete: true
      });
    } else {
      var index = reportsToDelete.indexOf(name);
      reportsToDelete.splice(index, 1);

      this.setState({
        reportsToDelete
      });
      if (reportsToDelete.length === 0) {
        this.setState({
          allowDelete: false
        });
      }
    }
  };

  selectAll = (event) => {
    const buttons = document.getElementsByClassName('deleteChecks');

    for (var i = 0, n = buttons.length; i < n; i++) {
      buttons[i].classList.remove('active');
    }

    event.target.classList.add('active');
    const checkboxes = document.getElementsByClassName('reportCheckbox');
    const reportsToDelete = [];

    for (var j = 0, m = checkboxes.length; j < m; j++) {
      checkboxes[j].checked = true;
    }

    this.state.reports.map(report => (
      reportsToDelete.push(report.id)
    ));

    this.setState({
      reportsToDelete, allowDelete: true
    });
  };

  selectNone = (event) => {
    const buttons = document.getElementsByClassName('deleteChecks');

    for (var i = 0, n = buttons.length; i < n; i++) {
      buttons[i].classList.remove('active');
    }

    if (typeof event !== 'undefined') event.target.classList.add('active');

    const checkboxes = document.getElementsByClassName('reportCheckbox');

    for (var j = 0, m = checkboxes.length; j < m; j++) {
      checkboxes[j].checked = false;
    }

    this.setState({
      reportsToDelete: [],
      allowDelete: false
    });
  };

  deleteReports = () => {
    deleteReports(this.state.reportsToDelete).then((data) => {
      if (data === 204) {
        this.getReportsList(
          this.state.currentPage,
          this.props.reportSettings.reportsPerPage,
          this.state.reportStatus,
          '',
          this.state.reportState,
          this.state.dateRange,
          this.state.reportFilters
        );

        this.setState({
          showDeleteTd: false
        });

        alert(this.state.reportsToDelete.length + ' reports were successfully deleted.');
      } else {
        this.setState({
          error: 'Server error. Please try again later.'
        });
      }
    });
  };

  _renderLoading = () => {
    return (<LoadingScreenLoggedIn />);
  };

  _renderList = () => {
    const reports = convertArrayToMap(this.state.reports);
    const todayDate = new Date();
    const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
    const today = moment(todayDate).format('D MMM YY');
    const yesterday = moment(yesterdayDate).format('D MMM YY');
    return (
      <div>
        {this.state.showDeleteTd && <div id="floatBanner" ref="floatArea" className={this.state.elementFloatAbove ? 'floatAbove' : 'no-float'}>
          <div className="float-inner-wrapper">
            <div id="floatBannerInner">
              <div className="floatLeft">
                <span className="transparentFont">Select</span>
                <button className="deleteChecks" onClick={this.selectAll}>All</button>
                <button className="deleteChecks" onClick={this.selectNone}>None</button>
              </div>
              <div className="floatRight">
                <button className={`errorBtn ${this.state.allowDelete ? 'active' : ''}`} onClick={this.deleteReports} disabled={!this.state.allowDelete}>Delete</button>
              </div>
            </div>
          </div>
        </div>}

        <div className={this.state.listLoader ? 'list is-loading' : 'list'} id="reportList">
          <div className="reportStatus">
            <div className="reportStatusLabel">Report status:&nbsp;</div>
            <div
              className={(!this.state.reportStatus || this.state.reportStatus === '') ? 'reportStatusOption active' : 'reportStatusOption'}
              onClick={() => this.toggleReportStatus('')}>All</div>
            <span className="reportStatusSeparator">/</span>
            <div
              className={this.state.reportStatus === 'open' ? 'reportStatusOption active' : 'reportStatusOption'}
              onClick={() => this.toggleReportStatus('open')}>Open</div>
            <span className="reportStatusSeparator">/</span>
            <div
              className={this.state.reportStatus === 'closed' ? 'reportStatusOption active' : 'reportStatusOption'}
              onClick={() => this.toggleReportStatus('closed')}>Closed</div>
          </div>
          {
            Object.keys(reports).map((key) => {
              const title = key === today ? 'today' : (key === yesterday ? 'yesterday' : key.substr(0, key.lastIndexOf(' ')));
              const items = reports[key];
              return (
                <div key={key}>
                  <h3>{title}</h3>
                  <Sublist reports={items} deleteReport={this.deleteReport} handleReportCheckbox={this.handleReportCheckbox} showDeleteTd={this.state.showDeleteTd} />
                </div>
              )
            })
          }
        </div>
        {<Paginator
          pageCount={this.state.pageCount}
          currentPage={this.state.currentPage}
          pageSize={this.state.pageSize}
          totalItems={this.state.totalItems}
          changePage={this.changePage}
          changePageSize={this.changePageSize} />}
      </div>
    )
  };

  _renderContent = () => {
    return (
      <div>
        {this.props.children ? this.props.children : this._renderList()}
      </div>
    )
  };

  render() {
    const isDownload = false;
    return (
      <DocumentTitle title='Wates Stop / Go Observation App Dashboard: Reports'>
        <LoggedInState>
          <ReportHeader
            view={this.props.children ? 'detail' : 'list'}
            isDownload={isDownload}
            activeFilter={this.state.activeFilter}
            reportState={this.state.reportState}
            searchReports={this.searchReports}
            changeReportFilterState={this.changeReportFilterState}
            changeDate={this.changeDate}
            showDeleteTd={this.state.showDeleteTd}
            toggleDeleteTd={this.toggleDeleteTd} />
          {this.state.isLoading ? this._renderLoading() : this._renderContent()}
        </LoggedInState>
      </DocumentTitle>
    )
  };
}

export default withParams(connect(mapStateToProps, mapDispatchToProps)(Reports));
