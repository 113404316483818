import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import Autocomplete from 'react-autocomplete';
import { gmAPIKEY } from '../../ajax/api';
import { Loader, LoaderOptions } from 'google-maps';
import { saveSite } from '../../ajax/AjaxSite';
import { getCoordinates } from '../../ajax/AjaxMap';
import { errorMessages } from './ErrorMessages';
import { getRegions, styles } from './Regions';
import { getBusiness } from './Business';
import { matchStateToTerm } from '../utils/Helpers';
import { validateString, validateIsEmpty, validateNumber, validateLength } from '../utils/Validator';

const options: LoaderOptions = {};
const loader = new Loader(gmAPIKEY, options);

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} {...props} />;
  };

  return Wrapper;
};

class SiteNew extends Component {
  constructor() {
    super();
    this.state = {
      address: '',
      name: '',
      pin: '',
      region: '',
      business: '',
      isArchived: 0,
      error: '',
      success: '',
      errorElements: [],
      lat: 55.378051,
      lng: -3.435973,
      showInfoTooltip: false,
      regionAnimate: false,
      businessAnimate: false,
      GoogleMap: null,
      Gmarkers: []
    }
  };

  componentDidMount() {
    const _self = this;
    loader.load().then(function (google) {
      const el = document.getElementById('map');
      const options = {
        zoom: 5,
        center: { lat: _self.state.lat, lng: _self.state.lng },
      }
      const GoogleMap = new google.maps.Map(el, options);

      _self.setState({
        GoogleMap: GoogleMap
      });
    });
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  submit = () => {
    this.resetMessages();
    const { address, name, pin, region, business, isArchived } = this.state;
    const saveData = { address, name, pin, region, business, isArchived };

    // validate and save
    if (this.validate()) {
      saveSite(saveData).then((data) => {
        if (data.status === 409) {
          this.setState({error: 'Site with this pin already exists.'});
        } else if (data.status > 400) {
          this.setState({error: 'Server error. Please try again later.'});
        } else {
          this.setState({error: ''});
          this.goToList();
        }
      });
    }
  }
  resetMessages = () => {
    this.setState({
      error: '',
      success: '',
      errorElements: []
    });
  };

  validate = () => {
    let isValid = true;
    const { address, name, pin } = this.state;
    let errorElements = this.state.errorElements;

    if (!validateString(name)) {
      errorElements.push('name');
      isValid = false;
    }

    if (!validateIsEmpty(address)) {
      errorElements.push('address');
      isValid = false;
    }

    if (!validateNumber(pin) || !validateLength(6, pin)) {
      errorElements.push('pin');
      isValid = false;
    }

    this.setState({ errorElements });
    return isValid;
  };

  goToList = () => {
    this.props.navigate('/dashboard/sites');
  };

  updateMap = (event) => {
    const _self = this;
    const value = event.target.value;
    getCoordinates(value).then((data) => {
      const GoogleMap = _self.state.GoogleMap;
      const Gmarkers = _self.state.Gmarkers;
      const { lat, lng } = data.results[0].geometry.location;

      // remove previos markers
      for (let i = 0; i < Gmarkers.length; i++) {
        Gmarkers[i].setMap(null);
      }

      GoogleMap.panTo({ lat, lng });
      GoogleMap.setZoom(16);

      loader.load().then(function (google) {
        var marker = new google.maps.Marker({
          position: { lat, lng },
          title: 'new marker',
          draggable: true,
          map: GoogleMap
        });

        Gmarkers.push(marker);
      });

      _self.setState({
        lat,
        lng,
        GoogleMap,
        Gmarkers
      });
    })
  };

  toggleInfoTooltip = () => {
    this.setState({ showInfoTooltip: !this.state.showInfoTooltip });
  };

  // blur region input
  regionBlur = () => {
    this.setState({ regionBlured: true });
    this.animateRegionLabel();
  };

  // blur business input
  businessBlur = () => {
    this.setState({ businessBlured: true });
    this.animateBusinessLabel();
  };

  // add animation to region label because of different structure of autocomplete
  animateRegionLabel = () => {
    if (!this.state.regionAnimate) {
      this.setState({ regionAnimate: true });
    } else {
      if (this.state.region === '') {
        this.setState({
          regionAnimate: false,
          regionBlured: false
        });
      }
    }
  }
  //add animation to business label because of different structure of autocomplete
  animateBusinessLabel = () => {
    if (!this.state.businessAnimate) {
      this.setState({ businessAnimate: true });
    } else {
      if (this.state.business === '') {
        this.setState({
          businessAnimate: false,
          businessBlured: false
        });
      }
    }
  };

  render() {
    const { address, name, pin } = this.state;
    const btnDisabled = address !== '' && name !== '' && pin !== '' ? false : true;
    return (
      <div className="siteCard">
        <h2 className="floatLeft">
          Add new site
        </h2>
        <div className="floatRight">
          <button className="closeButton" onClick={this.goToList}></button>
        </div>
        <div className="clear mapContainer">
          <div id="map" style={{ width: '100%', height: '270px' }} />
        </div>
        {this.state.error !== '' && <div className="errorRow mainFormError"><p>{this.state.error}</p></div>}
        {this.state.success !== '' && <div className="successRow"><p>{this.state.success}</p></div>}
        <div className="form" id="siteForm">
          <div className="formRow fullWidth noPadding">
            <input
              type="text"
              name="address"
              id="address"
              style={{ textTransform: 'capitalize' }}
              data-empty={this.state.address === '' ? 'true' : 'false'}
              className={`${this.state.errorElements.indexOf('address') >= 0 ? 'errorInput' : ''}`}
              onBlur={this.updateMap}
              value={this.state.address}
              onChange={this.handleChange} />
            {this.state.errorElements.indexOf('address') >= 0 && <span className="errorInputMsg">{errorMessages['address']}</span>}
            <label htmlFor="address">
              <span className="labelTitle">Site address</span>
            </label>
          </div>
          <div className="formRow">
            <input
              type="text"
              name="name"
              id="name"
              style={{ textTransform: 'capitalize' }}
              data-empty={this.state.name === '' ? 'true' : 'false'}
              className={`${this.state.errorElements.indexOf('name') >= 0 ? 'errorInput' : ''}`}
              value={this.state.name}
              onChange={this.handleChange} />
            {this.state.errorElements.indexOf('name') >= 0 && <span className="errorInputMsg">{errorMessages['name']}</span>}
            <label htmlFor="name">
              <span className="labelTitle">Site Name</span>
            </label>
          </div>
          <div className="formRow">
            <input
              type="number"
              name="pin"
              id="pin"
              data-empty={this.state.pin === '' ? 'true' : 'false'}
              className={`${this.state.errorElements.indexOf('pin') >= 0 ? 'errorInput' : ''}`}
              value={this.state.pin}
              onChange={this.handleChange} />
            {this.state.errorElements.indexOf('pin') >= 0 && <span className="errorInputMsg">{errorMessages['pin']}</span>}
            <label htmlFor="pin">
              <span className="labelTitle">Contract Number</span>
              <span className="infoIcon" onMouseOver={this.toggleInfoTooltip} onMouseOut={this.toggleInfoTooltip}></span>
            </label>
            <div className={`infoTooltip ${this.state.showInfoTooltip ? 'visible' : ''}`}>
              <p>Enter the 6 digit contract number for this site</p>
            </div>
          </div>
          <div className="formRow">
            <Autocomplete
              value={this.state.region}
              wrapperStyle={{ width: '100%' }}
              inputProps={{ name: 'region', id: 'region', className: 'isEmpty', onFocus: this.animateRegionLabel, onBlur: this.regionBlur }}
              items={getRegions()}
              getItemValue={(item) => item.name}
              shouldItemRender={matchStateToTerm}
              onChange={(event, value) => this.setState({ region: value })}
              onSelect={value => this.setState({ region: value })}
              renderItem={(item, isHighlighted) => (
                <div
                  style={isHighlighted ? styles.highlightedItem : styles.item}
                  key={item.abbr}>{item.name}</div>
              )}
              renderMenu={(items, value, style) => (
                <div className="autocompleteTooltip" children={items} />
              )}
            />
            <label htmlFor="region" className={this.state.regionAnimate ? 'animate' : ''}><span className={`labelTitle ${this.state.regionBlured ? 'transparentFont' : ''}`}>Region</span></label>
          </div>
          <div className="formRow">
            <Autocomplete
              value={this.state.business}
              wrapperStyle={{ width: '100%' }}
              inputProps={{ name: 'business', id: 'business', className: 'isEmpty', onFocus: this.animateBusinessLabel, onBlur: this.businessBlur }}
              items={getBusiness()}
              getItemValue={(item) => item.name}
              shouldItemRender={matchStateToTerm}
              onChange={(event, value) => this.setState({ business: value })}
              onSelect={value => this.setState({ business: value })}
              renderItem={(item, isHighlighted) => (
                <div
                  style={isHighlighted ? styles.highlightedItem : styles.item}
                  key={item.abbr}>{item.name}</div>
              )}
              renderMenu={(items, value, style) => (
                <div className="autocompleteTooltip" children={items} />
              )}
            />
            <label htmlFor="business" className={this.state.businessAnimate ? 'animate' : ''}><span className={`labelTitle ${this.state.businessBlured ? 'transparentFont' : ''}`}>Business</span></label>
          </div>
          <div className="buttons">
            <button
              className={` ${btnDisabled ? '' : 'activeBtn'} btnSuccess`}
              disabled={btnDisabled}
              onClick={this.submit}>Add</button>
          </div>
        </div>
      </div>
    )
  }
}

export default withParams(SiteNew);
