export const SET_JWT = 'SET_JWT';
export const CLEAR_JWT = 'CLEAR_JWT';

export function setJwt(jwt) {
  return { type: SET_JWT, jwt }
}

export function clearJwt() {
  return { type: CLEAR_JWT }
}
