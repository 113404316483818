import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import LoggedInState from '../../LoggedInState';
import { setColor } from '../../store/actions/bgcolor';
import store from '../../store/Store';
import { getUser, updateUser } from '../../ajax/AjaxUser';
import { validateString, validateEmail, validateName, validatePhone } from '../utils/Validator';
import { errorMessages } from './ErrorMessages';

const mapStateToProps = (store) => {
  return {
    user: store.user,
  }
};

export const withParams = (Component) => {
  const Wrapper = (props) => {
    return <Component navigate={useNavigate()} {...props} />;
  };

  return Wrapper;
};

class CurrentUser extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      jobTitle: '',
      company: '',
      email: '',
      phoneNumber: '',
      userRole: 0,
      isApproved: '',
      error: '',
      success: '',
      errorElements: [],
    }

    store.dispatch(setColor({ color: 'blueDarkBg' }));
  };

  // call for first load
  componentDidMount() {
    this.getUserById('me');
  };

  getUserById = (id) => {
    getUser(id).then((userData) => {
      this.setState(userData);
    });
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  resetMessages = () => {
    this.setState({
      error: "",
      success: "",
      errorElements: []
    });
  };

  goToList = () => {
    this.props.navigate('/dashboard/users');
  };

  validate = () => {
    let isValid = true;
    const { firstName, lastName, email, phoneNumber, jobTitle, company } = this.state;
    let errorElements = this.state.errorElements;

    if (!validateName(firstName)) {
      errorElements.push('firstName');
      isValid = false;
    }

    if (!validateName(lastName)) {
      errorElements.push('lastName');
      isValid = false;
    }

    if (!validateEmail(email)) {
      errorElements.push('email');
      isValid = false;
    }

    if (!validatePhone(phoneNumber)) {
      errorElements.push('phoneNumber');
      isValid = false;
    }

    if (!validateString(jobTitle)) {
      errorElements.push('jobTitle');
      isValid = false;
    }

    if (!validateString(company)) {
      errorElements.push('company');
      isValid = false;
    }

    this.setState({ errorElements });
    return isValid;
  };

  saveUser = () => {
    this.resetMessages();

    // validate and save
    if (this.validate()) {
      updateUser(this.state, this.props.user.id).then((data) => {
        if (data.status === 500) {
          this.setState({ error: 'Server error. Please try again later.' });
        } else if (data.status === 404) {
          this.setState({ error: 'You are trying to update non-existent user. Please refresh the page.' });
        } else {
          this.setState({ error: '', success: 'Your profile was successfully updated.' });
        }
      });
    }
  };

  render() {
    return (
      <DocumentTitle title='Wates Stop / Go Observation App Dashboard: Profile'>
        <LoggedInState>
          <div className="userCard">
            <div className="floatRight">
              <button className="closeButton" onClick={this.goToList}></button>
            </div>
            <h2 className="floatLeft">{this.state.firstName} {this.state.lastName}</h2>
            {this.state.error !== '' && <div className="errorRow mainFormError"><p>{this.state.error}</p></div>}
            {this.state.success !== '' && <div className="successRow"><p>{this.state.success}</p></div>}
            <div className="form" id="userForm">
              <div className="formRow">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  style={{ textTransform: "capitalize" }}
                  data-empty={this.state.firstName === '' ? 'true' : 'false'}
                  className={`${this.state.errorElements.includes('firstName') ? 'errorInput' : ''}`}
                  value={this.state.firstName}
                  onChange={this.handleChange} />
                {this.state.errorElements.includes('firstName') && <span className="errorInputMsg">{errorMessages['firstName']}</span>}
                <label htmlFor="firstName">
                  <span className="labelTitle">First name</span>
                </label>
              </div>
              <div className={`${this.state.userRole > 1 ? 'formRow' : 'formRow hidden'}`}>
                <label htmlFor="userRole" className="selectLabel">
                  <span>User level</span>
                  <select
                    name="userRole"
                    id="userRole"
                    value={this.state.userRole}
                    onChange={this.handleChange}>
                    <option value="0">Standard User</option>
                    {this.state.userRole > 0 && <option value="1">Site manager</option>}
                    {this.state.userRole > 1 && <option value="2">Admin user</option>}
                    {this.state.userRole > 2 && <option value="3">Super user</option>}
                  </select>
                </label>
              </div>
              <div className="formRow">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  style={{ textTransform: "capitalize" }}
                  data-empty={this.state.lastName === '' ? 'true' : 'false'}
                  className={`${this.state.errorElements.includes('lastName') ? 'errorInput' : ''}`}
                  value={this.state.lastName}
                  onChange={this.handleChange} />
                {this.state.errorElements.includes('lastName') && <span className="errorInputMsg">{errorMessages['lastName']}</span>}
                <label htmlFor="lastName">
                  <span className="labelTitle">Last name</span>
                </label>
              </div>
              <div className="formRow">
                <input
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  style={{ textTransform: "capitalize" }}
                  data-empty={this.state.jobTitle === '' ? 'true' : 'false'}
                  className={`${this.state.errorElements.includes('jobTitle') ? 'errorInput' : ''}`}
                  value={this.state.jobTitle}
                  onChange={this.handleChange} />
                {this.state.errorElements.includes('jobTitle') && <span className="errorInputMsg">{errorMessages['jobTitle']}</span>}
                <label htmlFor="jobTitle">
                  <span className="labelTitle">Job title</span>
                </label>
              </div>
              <div className="formRow">
                <input
                  type="email"
                  name="email"
                  id="email"
                  data-empty={this.state.email === '' ? 'true' : 'false'}
                  className={`${this.state.errorElements.includes('email') ? 'errorInput' : ''}`}
                  value={this.state.email}
                  onChange={this.handleChange} />
                {this.state.errorElements.includes('email') && <span className="errorInputMsg">{errorMessages['email']}</span>}
                <label htmlFor="email">
                  <span className="labelTitle">Email</span>
                </label>
              </div>
              <div className="formRow">
                <input
                  type="text"
                  name="company"
                  id="company"
                  style={{ textTransform: "capitalize" }}
                  data-empty={this.state.company === '' ? 'true' : 'false'}
                  className={`${this.state.errorElements.includes('company') ? 'errorInput' : ''}`}
                  value={this.state.company}
                  onChange={this.handleChange} />
                {this.state.errorElements.includes('company') && <span className="errorInputMsg">{errorMessages['company']}</span>}
                <label htmlFor="company">
                  <span className="labelTitle">Company</span>
                </label>
              </div>
              <div className="formRow">
                <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  data-empty={this.state.phoneNumber === '' ? 'true' : 'false'}
                  className={`${this.state.errorElements.includes('phoneNumber') ? 'errorInput' : ''}`}
                  value={this.state.phoneNumber}
                  onChange={this.handleChange} />
                {this.state.errorElements.includes('phoneNumber') && <span className="errorInputMsg">{errorMessages['phoneNumber']}</span>}
                <label htmlFor="phoneNumber">
                  <span className="labelTitle">Phone number</span>
                </label>
              </div>
              <div className="buttons">
                <button
                  className="btnSuccess"
                  onClick={this.saveUser}>Save</button>
              </div>
            </div>
          </div>
        </LoggedInState>
      </DocumentTitle>
    );
  }
}

export default withParams(connect(mapStateToProps, null)(CurrentUser));
