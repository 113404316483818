import React from 'react';

class ReportStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      loaded: false
    };
  };

  componentDidMount() {
    this.componentWillReceiveProps(this.props);
  };

  componentWillReceiveProps(props) {
    if (props.status && (props.status.errors !== undefined || props.status.totalErrors !== undefined || props.status.rows !== undefined)) {
      this.setState({
        errors: props.status.errors,
        totalErrors: props.status.totalErrors,
        totalRows: props.status.rows,
        loaded: true
      });
    }
  };

  render() {
    let totalErrors = this.state.loaded && this.state.totalErrors > 0 ?
      <div className="totalErrors">
        {this.state.totalErrors} error{this.state.totalErrors > 1 ? 's' : ''} occurred. Please correct your CSV and try again.
      </div> :
      '';

    let errors = this.state.loaded && this.state.errors !== undefined ?
      Object.keys(this.state.errors).map(id =>
        <div className="error" key={id}>
          <div className="row">Row: {this.state.errors[id].row}</div>
          <div className="column">Column: {this.state.errors[id].column}</div>
          <div className="rowData">Data: {this.state.errors[id].rowData}</div>
          <div className="message">{this.state.errors[id].error}</div>
        </div>) :
      [];

    let totalRows = this.state.totalRows > 0 ?
      <div className="rowsImported">{this.state.totalRows} row{this.state.totalRows > 1 ? 's' : ''} successfully imported.</div> :
      '';

    return (
      <div>
        <div>{totalErrors}</div>
        <div>{errors}</div>
        <div>{totalRows}</div>
      </div>
    );
  }
}

export default ReportStatus;
