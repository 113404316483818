import {apiURL} from './api';
import { logoutUser } from './AjaxLogout';
const url = apiURL + "download-report"

//send request for download
export const downloadRequest = (data, newWindow) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }
    newWindow.close();

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    if (response.status === 404) {
      alert('No data was found for that selection. Please review and try again.');
      return { error: true };
    }

    alert('An error occurred trying to access the download.');
    return { error: true };
  })
  .catch((err) => {
    console.log('Error getting download.');
    console.error(err);
    return { error: true };
  });
}

//get reports
export const getSites = (urlParameters) => {
  let getUrl = url + '?page=1&pageSize=-1';
  getUrl = typeof urlParameters.searchValue === "undefined" || urlParameters.searchValue === '' ? getUrl : getUrl + '&search=' + urlParameters.searchValue ;
  getUrl = typeof urlParameters.regionsToSend === "undefined" || urlParameters.regionsToSend.length === 0 ? getUrl : getUrl + '&region=' + urlParameters.regionsToSend;
  getUrl = typeof urlParameters.businessToSend === "undefined" || urlParameters.businessToSend.length === 0 ? getUrl : getUrl + '&business=' + urlParameters.businessToSend;
  getUrl = typeof urlParameters.labelsToSend === "undefined" || urlParameters.labelsToSend.length === 0 ? getUrl : getUrl + '&labels=[' + urlParameters.labelsToSend + ']';
  return fetch(getUrl, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
  .then((response) => {
    if (response.status < 400) {
      return response.json();
    }

    if (response.status === 401 || response.status === 403) {
      logoutUser();
      return {};
    }

    alert('An error occurred trying to access the sites information.');
    return {};
  })
  .catch((err) => {
    console.log('Error getting sites');
    console.error(err);
  });
}
