export function getRegions() {
  return [
    { abbr: "LN", name: "Construction London" },
    { abbr: "LNR", name: "Construction London Resi" },
    { abbr: "STH", name: "Construction Southern" },
    { abbr: "NHC", name: "Construction NHC" },
    { abbr: "MDL", name: "Construction Midlands" },
    { abbr: "NE", name: "Construction NE" },
    { abbr: "NW", name: "Construction NW" },
    { abbr: "RS", name: "Residential South" },
    { abbr: "LSSP", name: "LS South Planned" },
    { abbr: "LSSR", name: "LS South Responsive" },
    { abbr: "LSCP", name: "LS Central Planned" },
    { abbr: "LSCR", name: "LS Central Responsive" },
    { abbr: "LSNP", name: "LS North Planned" },
    { abbr: "LSNR", name: "LS North Responsive" },
    { abbr: "SMRT", name: "Smartspace" },
    { abbr: "SFM", name: "SFM" },
    { abbr: "WFM", name: "Wates FM" },
    { abbr: "SES", name: "SES (Engineering Services)" },
    { abbr: "GRP", name: "Group" }
  ]
};

export const styles = {
  item: {
    opacity:0.5
  },
  highlightedItem: {
    opacity: 1
  }
};
