export const SAVE_REPORTS_PER_PAGE = 'SAVE_REPORTS_PER_PAGE';
export const SAVE_PAGE_NUMBER = 'SAVE_PAGE_NUMBER';
export const SAVE_SEARCH_VALUE = 'SAVE_SEARCH_VALUE';
export const CLEAN_REPORT_SETTINGS_STORE = 'CLEAN_REPORT_SETTINGS_STORE';

export function saveReportsPerPage(reportsPerPage) {
  return { type: SAVE_REPORTS_PER_PAGE, reportsPerPage }
}

export function savePageNumber(pageNumber) {
  return { type: SAVE_PAGE_NUMBER, pageNumber }
}

export function saveSearchValue(searchValue) {
  return { type: SAVE_SEARCH_VALUE, searchValue }
}

export function cleanReportSettingsStore() {
  return { type: CLEAN_REPORT_SETTINGS_STORE }
}
