import React, { Component } from 'react';
import LoginBtn from './components/parts/LoginBtn';

class LoggedOutState extends Component {
  render() {
    return (
        <div className="middleContainer">
          <div className="middleBox">
            {this.props.showNav && false &&
              <ul className="nav">
                <LoginBtn value="Login" goTo="/login" />
                <LoginBtn value="Signup" goTo="/signup" />
              </ul>
            }
            {this.props.children}
          </div>
        </div>
    );
  }
}

export default LoggedOutState;
